import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardProductComponent } from '../card-product/card-product.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import Swiper from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ConfigService, GetExistencePerFilterCommand, GetProductsByFilterNameCommand, JsonTextService, LoadingService, Product, SioServicesService, TextResponse, filtroCadena } from 'libreria-tienda';
import { SliderLogoComponent } from '../slider-logo/slider-logo.component';

@Component({
  selector: 'lib-slider-card',
  standalone: true,
  imports: [CommonModule, CardProductComponent, NgxSkeletonLoaderModule, SliderLogoComponent],
  outputs: ['mensajeEnviado'],
  templateUrl: './slider-card.component.html',
  styleUrl: './slider-card.component.css'
})
export class SliderCardComponent implements OnInit {

  size: boolean = false;
  filters: GetExistencePerFilterCommand[];

  categoria: string;
  products: Product[];
  item: string;
  @Input() set info(value: any) {
    if (value.filtros !== undefined) {
      // console.log(value);
      this.item = value.item
      this.categoria = value.categoria;
      if (value.filtros.length > 0) {
        this.loadProductsDos(value.filtros, value.categoria);
      } else {
        this.loadProductsDos([], value.categoria);
      }
    }
  };
  @Input() title: string;

  getProductsByFilterNameCommand: GetProductsByFilterNameCommand;
  filtroCadena: filtroCadena;

  imagenProc: boolean = false;

  jsonCard: TextResponse[]

  public jsonService = inject(JsonTextService)
  public sioservice = inject(SioServicesService)
  public configService = inject(ConfigService)
  public load = inject(LoadingService)

  constructor(private cdref: ChangeDetectorRef) {
    this.jsonService.downloadJson(this.configService._config.URLJSONLOGIN).subscribe(res => {
      this.jsonCard = res

    })
  }

  ngOnInit() { }

  getName(name: string) {
    return this.jsonCard?.find(x => x.Nombre == name)?.Valor
  }

  eliminarDuplicados(array: Product[]): Product[] {
    const atributo: keyof Product = 'idProducto';
    const indices: { [key: string]: number } = {};
    return array.filter((objeto) => {
      if (!(objeto[atributo] in indices)) {
        indices[objeto[atributo]] = array.findIndex((el) => el[atributo] === objeto[atributo]);
      }
      return indices[objeto[atributo]] === array.indexOf(objeto);
    });
  }

  eliminarErrorImagen(arreglo: Product[]) {
    for (let i = 0; i < arreglo.length; i++) {
      const img = new Image();
      img.src = arreglo[i].imagen;
      img.onerror = () => {
        arreglo[i].errorImagen = true
      };
    }
    return arreglo
  }

  loadProductsDos(filtros: any[] = [], categoria: string, precioMin: number = 0, precioMax: number = 0) {
    this.load.cargar(true)
    this.sioservice.getArticulosPaginados(categoria, 15, 1, filtros, precioMin, precioMax).then((resultado) => {
      if (resultado.exitoso) {
        this.products = [...[]];
        this.products = this.products.concat(this.inicializarProducts(resultado.valor));
        // console.log(this.products);
        if (this.products.length == 0) {
          this.load.cargar(false);
          this.loadProductsDos(filtros.length > 0 ? filtros.pop() : [], this.categoria)
          // console.log(this.categoria);

        } else {
          let aux = this.products.findIndex(objeto => objeto.idProducto === this.item)
          if (aux !== -1) {
            this.products.splice(aux, 1);
          }
          this.load.cargar(false);
        }
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  inicializarProducts(data: any) {
    let aux2: Product[] = [];
    for (let index = 0; index < data.length; index++) {
      let aux: Product = {
        cantidad: data[index].cantidad,
        descripcionWeb: data[index].descripcionWeb,
        nombreComercial: data[index].nombreComercial,
        esGIA: data[index].esGIA,
        giA_PrecioMinimo: data[index].giA_PrecioMinimo,
        giA_PrecioMaximo: data[index].giA_PrecioMaximo,
        idProducto: data[index].idProducto,
        imagen: data[index].imagen,
        manejaTalla: data[index].manejaTalla,
        porcentaje: data[index].porcentaje,
        precioAutorizado: data[index].precioAutorizado,
        precioFull: data[index].precioFull,
        precioMaximo: data[index].precioMaximo,
        precioMinimo: data[index].precioMinimo,
        referenciaProveedor: data[index].referenciaProveedor,
        totalRegistros: data[index].totalRegistros,
        errorImagen: false,
        pesoPiedraCentral: data[index].pesoPiedraCentral,
        tipoProducto: this.linea(data[index].idLinea, data[index].esGIA)
      }
      aux2.push(aux)
    }
    // this.load.cargar(false)
    return aux2
  }

  linea(id: string, gia: boolean): string {
    switch (id) {
      case '41':
        if (gia) {
          return 'Gia';
        }
        return 'Anillo';
      case '31':
        if (gia) {
          return 'Gia';
        }
        return 'Anillo';
      case '01':
        return 'Anillo';
      case '03':
        return 'Anillo';
      case '28':
        return 'Venezia';
      default:
        return 'Normal';
    }
  }
}
