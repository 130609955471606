<div class="d-none d-sm-none d-md-none d-lg-block">
  <div class="pt-3">
    <span class="reference">Ref. {{codigo}}</span>
  </div>
  @if (item.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{item.precioFull | number}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">$ {{item.precioAutorizado |
      number}}</span>
    @if (item.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (item.porcentaje < 15 && item.porcentaje !==0) { <span>Precio exclusivo para ventas online</span>
    }
    <div>
      <div class="pt-3">
        <span class="characteristics">{{getName('cara')}}</span>
      </div>
      <!-- @for (characteristic of caracteristicas; track $index) {
      <div class="d-flex-center" [ngClass]="{'pb-3' : $index == (caracteristicas.length -1)}">
        @if (characteristic.tipo == 1) {
        <span class="circle"></span>
        <span class="details">{{characteristic.texto}}</span>
        }
      </div>
      } -->
      <mat-accordion class="pt-3">
        <mat-expansion-panel (opened)="onPanelOpened()" class="border-expansion" (closed)="onPanelClosed()"
          hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="no-wrap">{{getName('mas-detalles')}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="color-black" *ngIf="!open ">add
              </mat-icon>
              <mat-icon class="color-black" *ngIf="open">
                remove</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <div *ngFor="let characteristic of caracteristicas" class="d-flex-align-end d-flex-row pr-2">
            <span *ngIf="characteristic.tipo == 2">{{characteristic.texto}}</span>
          </div> -->
        </mat-expansion-panel>
        <mat-divider></mat-divider>
      </mat-accordion>
    </div>
    <div class="d-flex-column pt-3">
      <span class="cant pb-2">{{getName('cant')}}</span>
      <div class="d-flex-row">
        <button class="button-addremove" (click)="menos()">
          <mat-icon>remove</mat-icon>
        </button>
        <input type="number" id="cant" #cant value="{{ item.cantidad }}" min=1 name="" id="" placeholder="Cantidad"
          class="input-class input-cant" (keydown)="handleKeydown($event)" readonly>
        <button class="button-addremove" (click)="mas()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    @if (unico) {
    <mat-error class="pt-3 d-flex-center">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
        favor
        Comunicate con servicio al cliente</span>
    </mat-error>
    }
    <div class="pt-3">
      <span class="cant pb-2">¿ Te gustaría agregar una de nuestras pulseras ? </span>
      <div class="d-flex-row pt-3">
        @for (item of complementos; track $index) {
        <lib-complemento [info]="item" class="pl-1rem" (selectComple)="complemtoSele($index, $event)" />
        }
      </div>
    </div>
    <div class="d-flex-column gap pt-3">
      <div class="d-flex-row d-flex-center">
        <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}</button>
        <lib-favorito-corazon class="pl-1rem" />
      </div>
      <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('politics')"></span>
    </div>
</div>

<!-- Mobile -->

<div class="d-block d-sm-block d-md-block d-lg-none espacioooooooooooooo">
  <div class="d-flex-column">
    <span class="reference pl-2rem pl-5rem pt-3">Ref. {{item.codigo}}</span>
    <!-- <span class="price pl-2rem pl-5rem">$ {{item.precioAutorizado | number}}</span> -->
    @if (item.porcentaje !== 0) {
    <div class="pl-2rem pl-5rem">
      <span class="price price-full">$ {{item.precioFull | number}}</span>
    </div>
    }
    <div class="d-flex-row pl-2rem pl-5rem">
      <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">
        $ {{item.precioAutorizado | number}}
      </span>
      @if (item.porcentaje !== 0) {
      <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
      }
    </div>
    <span class="characteristics pl-2rem pl-5rem pt-3">{{getName('cara')}}</span>
    <div>
      <!-- @for (characteristic of caracteristicas; track $index) {
      <div class="d-flex-center pl-2rem pl-5rem">
        @if (characteristic.tipo == 1) {
        <span class="circle"></span>
        <span class="details">{{characteristic.texto}}</span>
        }
      </div>
      } -->
      <mat-accordion class="pt-3">
        <mat-expansion-panel hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="no-wrap pl-4rem">{{getName('mas-detalles')}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="color-black" *ngIf="!open ">add</mat-icon>
              <mat-icon class="color-black" *ngIf="open">remove</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- @for (characteristic of caracteristicas; track $index) {
          <div class="d-flex-align-end d-flex-row pr-2 pl-2rem pl-4rem">
            @if (characteristic.tipo == 2) {
            <span>{{characteristic.texto}}</span>
            }
          </div>
          } -->
        </mat-expansion-panel>
        <mat-divider></mat-divider>
      </mat-accordion>
    </div>
    <span (click)="donwloadPolitics()" class="politics pl-2rem cursor-pointer  pt-3"
      [innerHTML]="getName('politics')"></span>
    <div class="d-flex-row space-eve pl-2rem pl-5rem pt-3">
      <div class="d-flex-row">
        <button class="button-addremove" (click)="menos()">
          <mat-icon class="font-size-21">remove</mat-icon>
        </button>
        <input type="number" id="cant" #cant value="{{ item.cantidad }}" min=1 name="" id="" placeholder="Cant"
          class="input-class" (keydown)="handleKeydown($event)" readonly>
        <button class="button-addremove" (click)="mas()">
          <mat-icon class="font-size-21">add</mat-icon>
        </button>
      </div>
      <div class="but-container">
        <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}</button>
      </div>
    </div>
    <mat-error class="pt-3 d-flex-center pl-2rem pl-5rem pr-2rem" *ngIf="item.talla == '-' && submited">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla para tu
        producto.</span>
    </mat-error>
    <mat-error class="pt-3 d-flex-center pl-2rem pl-5rem pr-2rem" *ngIf="unico">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Último producto en la compañia. Por favor
        Comunicate con servicio al cliente</span>
    </mat-error>
  </div>

</div>
