<div class="d-none d-sm-none d-md-none d-lg-block pl-pr-100 block-pc show-pc">
  <div class="contenedor">
    <div class="fondo">
      <span [innerHTML]="propiedades['gia']['title']" class="titulo-fondo"></span>
    </div>
    <div class="contenedor-contenido">
      <div class="contenedor-text">
        <div class="titulo-altura">
          <span [innerHTML]="propiedades['gia']['title']" class="titulo"></span>
          <span [innerHTML]="propiedades['gia']['subtitle']" class="textos"></span>
        </div>
        <div class="contenedor-botones">
          <button [innerHTML]="propiedades['gia']['but1']" class="botones primero" (click)="navigate(propiedades['gia']['link-boton-uno'])"></button>
          <span class="espacio-o">O</span>
          <button [innerHTML]="propiedades['gia']['but2']" class="botones segundo"></button>
        </div>
      </div>
      <div class="contenedor-img">
        <img [src]="propiedades['gia']['anillo']" alt="" class="img-size">
        <img [src]="propiedades['gia']['base']" alt="" class="img-size img-complemento">
        <!-- <img [src]="propiedades['gia']['video']" alt="" class="img-size"> -->
        <div class="diamanate-moviento" [style.backgroundPosition]="mov_diamante" [style.top.px]="top" [ngStyle]="{ 'background-image': 'url(' + propiedades['gia']['piedra'] + ')' }"></div>
      </div>
    </div>
  </div>

</div>


<div class="d-block d-sm-block d-md-block d-lg-none block-small show-mobile">

</div>
