import { Component, EventEmitter, Output, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-header-mobile',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatIconModule],
  templateUrl: './header-mobile.component.html',
  styleUrl: './header-mobile.component.css'
})
export class HeaderMobileComponent {

  @Output() evento = new EventEmitter<string>();

  private headerJson: TextResponse[];

  protected logo: any;

  private rutaActual: string;

  private jsonService = inject(JsonTextService)
  private configService = inject(ConfigService)

  constructor(private router: Router) { }

  ngOnInit(): void {
    // this.jsonService.downloadJson(this.configService._config.URLHEADERJSON).subscribe(res => {
    //   this.headerJson = res;
    // })
    this.logo = this.getName("logo-marca")
    this.logo = 'https://qastorageorafa.blob.core.windows.net/generales/Kevins_Logo.svg'
  }

  protected getName(name: string) {
    return this.headerJson?.find(x => x.Nombre == name)?.Valor
  }

  protected EventoOpen(val: string) {
    this.evento.emit(val)
  }

  protected redirectToHome() {
    this.rutaActual = this.router.url
    if (this.rutaActual != "/home") {
      this.router.navigate(['/home']).then(() => {
        window.location.reload();
      });
    }
  }

}
