<div class="container-colecciones">
  <div class="d-flex-center pt-3 pr-5-rem">
    <span class="title-section">{{getName('title')}}</span>
  </div>

  <div class="d-none d-sm-none d-md-none d-lg-block justy-eve pl-pr-100 g-2 d-flex-important showpc">
    @for (cate of jsonImgCategories; track $index) {
    <div class="card-container d-flex-column" (click)="redirect(cate.link)">
      <div class="center">
        <img class="img-size" src={{cate.img}} alt="">
      </div>
      <div class="end">
        <span class="end-text">{{cate.marca}}</span>
      </div>
    </div>
    }
  </div>

  <div class="d-flex-column d-block d-sm-block d-md-block d-lg-none d-flex-row justy-eve g-2 phone-wrap showmob">
    @for (cate of jsonImgCategories; track $index) {
    <div class="card-container d-flex-column" (click)="redirect(cate.marca)">
      <div class="center">
        <a class="img-a">
          <img class="img-size" src={{cate.img}} alt="">
        </a>
      </div>
      <div class="end">
        <span class="text-categories pb-1">{{cate.marca}}</span>
      </div>
    </div>
    }
  </div>
</div>
