<div class="d-none d-sm-none d-md-none d-lg-block">
  @if (!giaSeleccionado) {
  <div class="pt-3">
    <span class="reference">{{getName('gia-desde')}}</span>
  </div>
  <div>
    <span class="price price-auto">$ {{precioMinimo | number}}</span>
  </div>
  }@else {
  <div class="pt-3">
    <span class="reference">Ref. {{infoGiaSeleccionado.idProducto}}</span>
  </div>
  @if (infoGiaSeleccionado.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{infoGiaSeleccionado.precioFull | number}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':infoGiaSeleccionado.porcentaje !== 0}">$
      {{infoGiaSeleccionado.precioAutorizado | number}}</span>
    @if (infoGiaSeleccionado.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="infoGiaSeleccionado.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (infoGiaSeleccionado.porcentaje < 15 && infoGiaSeleccionado.porcentaje !==0) { <span>Precio exclusivo para ventas
    online</span> }
    }
    <div>
      <span class="price price-auto">{{getName('gia-title-1')}}</span>
    </div>
    <div>
      <span class="info-gia">{{getName('gia-info-1')}}</span>
    </div>
    <div class="d-flex-row gap pt-3">
      <lib-tallas [tallas]="tallas" [submited]="submited" [tallaSelectd]="infoGiaSeleccionado.talla"
        (ItemData)="talla($event)"></lib-tallas>
    </div>
    <div class="d-flex-row d-flex-center pt-2">
      <span class="icon-ring font-size-16"></span>
      <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">{{getName('manual-tallas')}}</span>
    </div>
    @if (tallaSelec || giaSeleccionado) {
    <div class="d-flex-row gap pt-3">
      <lib-referencias [tallas]="referencias" [submited]="submited" [tallaSelectd]="infoGiaSeleccionado.idProducto"
        (ItemData)="referencia($event)"></lib-referencias>
    </div>
    }
    <div>
      <div class="pt-3">
        <span class="characteristics">{{getName('cara')}}</span>
      </div>
      <div>
        <div class="pt-3 border-bottom">
          <span class="characteristics">{{getName('desc')}}</span>
        </div>
        <div class="d-flex-center">
          @if (item !== undefined) {
          <span class="details">{{item[0].descripcion}}:</span>
          }
        </div>
        <mat-accordion class="pt-3">
          <mat-expansion-panel (opened)="onPanelOpened()" (closed)="onPanelClosed()" hideToggle="true">
            <mat-expansion-panel-header [ngClass]="{'aplicar-borde': open}">
              <mat-panel-title>
                <span class="no-wrap">{{getName('caract')}}</span>
              </mat-panel-title>
              <mat-panel-description>
                @if (open) {
                <i class="fa-solid fa-angle-up"></i>
                }@else {
                <i class="fa-solid fa-angle-down"></i>
                }
              </mat-panel-description>
            </mat-expansion-panel-header>
            @for (caracteristica of caracteristicasItem; track $index) {
            @if (caracteristica.valor !== null && caracteristica.valor !== '' && caracteristica.valor !== undefined) {
            <div class="d-flex-align-end d-flex-row pr-2">
              <span class="titulos-carac color-textos">{{caracteristica.web}}:</span>
              <span class="color-textos">{{caracteristica.valor | titlecase }}</span>
            </div>
            }
            }
          </mat-expansion-panel>
          <mat-divider></mat-divider>
        </mat-accordion>
      </div>
    </div>
    <mat-error class="pt-3 d-flex-center" *ngIf="(infoGiaSeleccionado.talla == '-' && submited) || !selectSize">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla
        para tu producto.</span>
    </mat-error>
    <mat-error class="pt-3 d-flex-center" *ngIf="unico && submited">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
        favor
        Comunicate con servicio al cliente</span>
    </mat-error>
    <div class="pt-3 pr-2rem">
      <div class="pb-3">
        <span class="titles-gia">{{getName('gia-title-2')}}</span>
      </div>
      <div class="pb-3 pr-2rem">
        <span class="info-gia">{{getName('gia-info-2')}}</span>
      </div>
      <button (click)="openChat()" class="button-secondary">{{getName('gia-but')}}</button>
    </div>
</div>

<!-- Mobile -->

<div class="d-block d-sm-block d-md-block d-lg-none espacioooooooooooooo">
  <div class="d-flex-column">
    <!-- <span class="reference pl-2rem pl-5rem pt-3">Ref. {{item.codigo}}</span> -->
    <!-- <span class="price pl-2rem pl-5rem">$ {{item.precioAutorizado | number}}</span> -->
    <!-- @if (item.porcentaje !== 0) {
    <div class="pl-2rem pl-5rem">
      <span class="price price-full">$ {{item.precioFull | number}}</span>
    </div>
    } -->
    <div class="d-flex-row pl-2rem pl-5rem">
      <!-- <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">
        $ {{item.precioAutorizado | number}}
      </span> -->
      <!-- @if (item.porcentaje !== 0) {
      <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
      } -->
    </div>
    <span class="characteristics pl-2rem pl-5rem pt-3">{{getName('cara')}}</span>
    <div>
      <!-- @for (characteristic of caracteristicas; track $index) {
      <div class="d-flex-center pl-2rem pl-5rem">
        @if (characteristic.tipo == 1) {
        <span class="circle"></span>
        <span class="details">{{characteristic.texto}}</span>
        }
      </div>
      } -->
      <mat-accordion class="pt-3">
        <mat-expansion-panel hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="no-wrap pl-4rem">{{getName('mas-detalles')}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="color-black" *ngIf="!open ">add</mat-icon>
              <mat-icon class="color-black" *ngIf="open">remove</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- @for (characteristic of caracteristicas; track $index) {
          <div class="d-flex-align-end d-flex-row pr-2 pl-2rem pl-4rem">
            @if (characteristic.tipo == 2) {
            <span>{{characteristic.texto}}</span>
            }
          </div>
          } -->
        </mat-expansion-panel>
        <mat-divider></mat-divider>
      </mat-accordion>
    </div>
    <span (click)="donwloadPolitics()" class="politics pl-2rem cursor-pointer  pt-3"
      [innerHTML]="getName('politics')"></span>
    <div class="pr-1rem pl-2rem pl-5rem pt-3">
      <div>
        <!-- <lib-tallas [tallas]="tallas" [tallaSelectd]="item.talla || '-'" [submited]="submited" [codTallas]="codTallas"
          (ItemData)="talla($event)"></lib-tallas> -->
      </div>
      <div class="d-flex-row d-flex-center pt-3">
        <span class="icon-ring font-size-20"></span>
        <span (click)="donwloadManual()" class="manual pl-1rem cursor-pointer">{{getName('manual-tallas')}}</span>
      </div>
    </div>
    <div class="d-flex-row space-eve pl-2rem pl-5rem pt-3">
      <div class="but-container">
        <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}</button>
      </div>
    </div>
    <!-- <mat-error class="pt-3 d-flex-center pl-2rem pl-5rem pr-2rem" *ngIf="item.talla == '-' && submited">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla para tu
        producto.</span>
    </mat-error> -->
    <mat-error class="pt-3 d-flex-center pl-2rem pl-5rem pr-2rem" *ngIf="unico && submited">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por favor
        Comunicate con servicio al cliente</span>
    </mat-error>
  </div>

</div>
