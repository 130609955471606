export class filtroAplicado {
  filtros: number[];
  precioMax: number = 0;
  precioMin: number = 0;
}

export class PreciosFiltros {
  precioMax: number = 0;
  precioMin: number = 0;
}

