<div class="d-flex-row header-filters z-index-3"
  [ngClass]="{'z-index-3-mobile':dispositivoService.isMobile, 'px-100':!dispositivoService.isMobile}">
  <div class="w-100 grid pt-3 pb-3">
    <div class="g-col-6 pt-2">
      <!-- <span class="text-header-catalog pr-2 cursor-pointer" (click)="routing()">Inicio</span>
      <mat-divider vertical class="divider"></mat-divider>
      <span class="text-header-catalog pll-10">{{categoria ? categoria : filtroUsuario }}</span> -->
      <lib-miga-pan [migas]="migas" [indicador]="'compra'"></lib-miga-pan>
    </div>
    <div class="g-col-6 end ">
      <div class="d-none d-sm-none d-md-none d-lg-block">
        <div class="w-9rem">
          @if (categoria !== 'Favoritos') {
          <button (click)="filtros()" class="buton-filters">Filtros <span
              class="icon-filtros color-icon-wh color-icon-h pl-1"></span>
          </button>
          }
        </div>
      </div>
      <div class="center-views pl-2">
        <span *ngIf="cont == 1" (click)="camb()" class="icon-Vista_corta font-25 pl-2 mt-05">
          <div class="d-none d-sm-none d-md-none d-lg-block">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
          </div>
          <div class="d-sm-block d-md-block d-lg-none center-top">
            <span class="path1"></span>
            <span class="path2"></span>
          </div>
        </span>
        <span *ngIf="cont == 2" (click)="camb()" class="icon-semicompleto font-25 pl-2 mt-05">
          <div class="d-none d-sm-none d-md-none d-lg-block">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
          </div>
          <div class="d-sm-block d-md-block d-lg-none center-top">
            <span class="path1"></span>
            <span class="path2"></span>
          </div>
        </span>
        <span *ngIf="cont == 3" (click)="camb()" class="icon-completo font-25 pl-2 mt-05"></span>
      </div>
    </div>
  </div>
</div>
