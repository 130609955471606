import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, ShoppingCartService, TextResponse, User } from 'libreria-tienda';

@Component({
  selector: 'lib-menu-inferior-mobile',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './menu-inferior-mobile.component.html',
  styleUrl: './menu-inferior-mobile.component.css'
})
export class MenuInferiorMobileComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();

  user: User = new User();
  iniciales: string = "-";
  totalProducts: number;
  whatsApp: any;

  headerJson: TextResponse[];

  rutaActual: string;

  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)

  constructor(private router: Router) { }

  ngOnInit(): void {
    // this.jsonService.downloadJson(this.configService._config.URLHEADERJSON).subscribe(res => {
    //   this.headerJson = res;
    // })
    this.whatsApp = this.getName("urlWhatsApp")
    this.verificarSesion()
    if (this.user.nombre != undefined && this.user.apellido != undefined) {
      this.iniciales = this.user.nombre.charAt(0) + this.user.apellido.charAt(0);
    } else {
      this.iniciales = "-";
    }
    this.rutaActual = this.router.url;
    console.log(this.router.url);
  }

  public cartService = inject(ShoppingCartService)


  routing(url: string) {
    console.log(url);
    if (url !== 'profile-main') {
      this.router.navigate([`/${url}`])
    }
  }

  openWa() {
    window.open(this.whatsApp, '_blank');
  }

  protected goCart() {
    this.rutaActual = this.router.url;
    if (this.rutaActual == '/shopping-cart') {
      this.router.navigate([`/home`])
    } else {
      this.router.navigate([`/shopping-cart`])
    }
  }

  ngAfterContentChecked() {
    this.totalProducts = this.cartService.getTotalProducts()
    this.rutaActual = this.router.url;
  }

  verificarSesion() {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem("userLogged") != undefined) {
        this.user = JSON.parse(localStorage.getItem("userLogged") || '')
      }
    }
  }

  getName(name: string) {
    return this.headerJson?.find(x => x.Nombre == name)?.Valor
  }



}
