@if (max !== 0) {
<div class="contenedor">
  <div class="slider-container">
    @if (max !== min) {
    <div class="control-deslizante">
      <div class="title-control">
        <span>Precio</span>
        <mat-divider></mat-divider>
      </div>
      <lib-slider-range-price [min]="min" [max]="max" (cambioRangoSelect)="sueltaRangoCambio($event)"
        (cambiandoMax)="cambiandoRangoMax($event)" (cambiandoMin)="cambiandoRangoMin($event)" />
      <!-- <div class="barra-precio">

        <input type="range" id="rango" min="{{ min }}" max="{{ max }}" step="{{ step }}"
          [(ngModel)]="precioMinSeleccionado" (change)="sueltaRangoCambioMin($event)">
        <input type="range" id="rango2" min="{{ min }}" max="{{ max }}" step="{{ step2 }}"
          [(ngModel)]="precioMaxSeleccionado" (change)="sueltaRangoCambioMax($event)">
      </div> -->
      <div class="control-numeros">
        <div class="desde">
          <span class="desde-hasta-texto">Desde</span>
          <input type="text" id="valor1" class="desde-hasta-input" [(ngModel)]="precioMinSeleccionadoText">
        </div>
        <div class="separador-input">-</div>
        <div class="hasta">
          <input type="text" id="valor2" class="desde-hasta-input" [(ngModel)]="precioMaxSeleccionadoText">
          <span class="desde-hasta-texto">Hasta</span>
        </div>
      </div>
    </div>
    }
    <div class="control-filtros">
      <div class="title-control">
        <span>Filtros</span>
        <mat-divider></mat-divider>
      </div>
      <!-- <div class="contenedor-botones">
          <button (click)="filtrosAplicadosArray()" class="boton-aplicar-eliminar boton-aplicar">Aplicar filtros
            <img src="https://qastorageorafa.blob.core.windows.net/iconos/icono y.png" alt="" class="size-icono-boton">
          </button>
          <button (click)="eliminarfiltros()" class="boton-aplicar-eliminar boton-eliminar">Limpiar filtros
            <img src="https://qastorageorafa.blob.core.windows.net/iconos/icono x.png" alt="" class="size-icono-boton">
          </button>
        </div> -->
      <div class="contenedor-filtros">
        <mat-accordion>
          <div *ngFor="let item of typesOfShoes; let i = index">
            <mat-expansion-panel [expanded]="step3() === i" (opened)="setStep(0)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="title-filtro">
                    <span>{{item.grupoPropiedad.grupoPropiedad}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              @for(shoe of item.valor; track $index){
              <div class="filtro">
                @if (!shoe.disable) {
                <div class="checkBok">
                  <input type="checkbox" id="cbx-{{ shoe.nombreWeb }}" style="display: none;" [(ngModel)]="shoe.select"
                    [disabled]="shoe.disable" (change)="onCheckboxChange()">
                  <label for="cbx-{{ shoe.nombreWeb }}" class="check" [ngClass]="{'check-disable':shoe.disable}">
                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                      <path
                        d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                      <polyline points="1 9 7 14 15 4"></polyline>
                    </svg>
                  </label>
                </div>
                }
                <label for="cbx-{{ shoe.nombreWeb }}" class="texto-filtro"
                  [ngClass]="{'text-select':shoe.select,'text-disable':shoe.disable}">{{ shoe.nombreWeb }}</label>
              </div>
              }
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
}
