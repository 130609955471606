import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-tipo-compra',
  standalone: true,
  imports: [],
  templateUrl: './tipo-compra.component.html',
  styleUrl: './tipo-compra.component.css'
})
export class TipoCompraComponent {

  constructor(private router: Router, public dialogRef: MatDialogRef<TipoCompraComponent>) { }

  cerrarDialogo(valor: string): void {
    this.dialogRef.close({ valor: valor });
  }

}
