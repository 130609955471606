<lib-barra-superior></lib-barra-superior>
<div class="header-container">
  <div class="header" [ngClass]="{'header-sale':sale}">
    <!-- <div class="z-index">
      <a class="align-center" [href]="whatsApp" target="_blank">
        <span class="icon-Whatsapp icon-size black-color"></span>
        <span class="text-banner ">{{getName('whats')}}</span>
      </a>
    </div> -->
    <div class="kevins-center">
      <a (click)="redirectToHome()">
        <img class="logo-size z-index-2 pos-rela" [src]="propiedades['header']['logo-marca']" alt="">
      </a>
    </div>
    <div class="icons-right">
      <lib-busqueda (click)="EventoOpen('Busqueda')" />
      <div class="z-index flex-relative pl-2" (click)="EventoOpen('Carrito')">
        <a>
          <span class="icon-shoppingCart icon-size icon-color-b" [ngClass]="{'icon-color-sale':sale}"></span>
          @if (totalProducts > 0) {
          <span class="numberProducts">{{totalProducts}}</span>
          }
        </a>
      </div>
      <span class="icon-Hearth flex-relative black-color icon-size pl-2" [ngClass]="{'icon-color-sale':sale}"(click)="redirect('favoritos')"></span>
      @if (login) {
      <div class="circle-iniciales z-index pointer" [matMenuTriggerFor]="beforeMenu">
        <span>{{iniciales}}</span>
      </div>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="menu-perfil">
        <button mat-menu-item (click)="redirect('perfil')">Ver perfil</button>
        <mat-divider ></mat-divider>
        <button mat-menu-item (click)="cerrarSesion()">Cerrar sesión</button>
      </mat-menu>

      }@else {
      <div (click)="redirect('login')" class="z-index pointer">
        <span class="icon-User black-color icon-size pl-2" [ngClass]="{'icon-color-sale':sale}"></span>
      </div>
      }
    </div>
  </div>
</div>
<lib-menus></lib-menus>
