<div class="container">
  <div class="wrapper" (mouseenter)="stopAutoPlay()" (mouseleave)="startAutoPlay()">
    <i id="left" class="fa-solid fa-angle-left" (click)="scrollCarousel('left')"></i>
    <ul class="carousel" #carousel>
      @for (item of marcas; track $index) {
      <li class="card" (click)="navegar(item.link)">
        <!-- <img [src]="item.img" alt=""> -->
        <img class="img-size view-card pointer" [src]="item.img" [alt]="item.marca">
      </li>
      }
    </ul>
    <i id="right" class="fa-solid fa-angle-right" (click)="scrollCarousel('right')"></i>
  </div>
</div>
