import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { DispositivoService, filterActiveShow } from 'libreria-tienda';
import { MigaPanComponent } from 'libreria-tienda/shared/miga-pan/miga-pan.component';
import { ManejadorEventosService } from '../../../src/lib/services/manejador-eventos.service';

@Component({
  selector: 'lib-menu-catalogo',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MigaPanComponent],
  templateUrl: './menu-catalogo.component.html',
  styleUrl: './menu-catalogo.component.css'
})
export class MenuCatalogoComponent implements OnInit {

  @Output() changeColumns = new EventEmitter<string>();
  @Output() activeFiltros = new EventEmitter<boolean>();

  migas: string[] = [];

  open: boolean = false;

  public mobile: boolean = false;

  @Input() categoria: string = "";
  filtroUsuario: string = ""
  clase = "wrapper-product"

  filtersActiveTotal: number = 0
  cont = 1;

  filterActiveShow: Array<filterActiveShow> = new Array<filterActiveShow>();

  public dispositivoService = inject(DispositivoService)
  public manejadorEventosService = inject(ManejadorEventosService)

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.migas.push(this.categoria)
    if (typeof screen !== 'undefined') {
      if (screen.width < 1023) {
        this.mobile = true
      }
    }
  }

  routing() {
    this.router.navigate(["/home"]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  camb() {
    this.cont++;
    if (!this.mobile) {
      if (this.cont == 3) {
        this.clase = "wrapper-product3"
      }
      if (this.cont == 2) {
        this.clase = "wrapper-product2"
      }
      if (this.cont == 1) {
        this.clase = "wrapper-product"
      }
      if (this.cont == 4) {
        this.cont = 1
        this.clase = "wrapper-product"
      }
    } else {
      if (this.cont == 3) {
        this.cont = 1
        this.clase = "wrapper-product"
      }
      if (this.cont == 2) {
        this.clase = "wrapper-product2"
      }
      if (this.cont == 1) {
        this.clase = "wrapper-product"
      }
    }
    this.manejadorEventosService.lanzarEvento('changeColumns');
    this.changeColumns.emit(this.clase)
  }
  filtros() {
    this.open = !this.open;
    this.activeFiltros.emit(this.open)
  }

}
