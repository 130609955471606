declare var google: any;
import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormsModule, Validators, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { ExpressService, ItemCar, JsonTextService, LoadingService, Product, TextResponse, TokenStorageService, User, UserService, favorite, userlogged } from 'libreria-tienda';
import { PopupCorreoRegistroComponent } from 'libreria-tienda/shared/popup-correo-registro/popup-correo-registro.component';
import { MatDialog } from '@angular/material/dialog';
import * as i0 from '@angular/core';

@Component({
  selector: 'app-autenticacion',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.KEYGOOGLERECAPTCHA,
      } as RecaptchaSettings,
    },
  ],
  templateUrl: './autenticacion.component.html',
  styleUrl: './autenticacion.component.css'
})
export class AutenticacionComponent implements OnInit, AfterViewInit {

  loginJson: TextResponse[]

  loginSocial: User = new User();

  idCienteGoogle: string;

  LoginForm: FormGroup;
  hide: boolean = true;

  token: string | undefined;

  jsonService = inject(JsonTextService)
  expressService = inject(ExpressService)
  userService = inject(UserService)
  tokenStorage = inject(TokenStorageService)
  load = inject(LoadingService)
  router = inject(Router)

  submit: boolean = false;

  constructor(private fb: FormBuilder, public dialog: MatDialog) {
    this.LoginForm = this.initForm()
    this.token = undefined;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // console.log(environment.KEYGOOGLECLIENT);

    this.jsonService.downloadJson(environment.URLJSONLOGIN).subscribe(res => {
      this.loginJson = res;
      this.load.cargar(false)
    })
    google.accounts.id.initialize({
      // client_id: '798742616400-20hbsn4oi880ah3tjt9mkj2buduoi3t9.apps.googleusercontent.com',
      // client_id: '301237490382-tg8m3reommpu1uuoiqdpp455qraub66g.apps.googleusercontent.com',
      client_id: environment.KEYGOOGLECLIENT,
      callback: (resp: any) => {
        this.handleLogin(resp)
      }
    });

    google.accounts.id.renderButton(document.getElementById("google-btn"), {
      theme: 'filled_black',
      size: 'large',
      shape: 'circle',
      width: 250
    })
  }

  ngAfterViewInit(): void {
    // Scroll hasta la parte superior cuando la página se carga por primera vez
    window.scrollTo(0, 0);
  }

  initForm(): FormGroup {
    // Inicializa el formulario y define los controles
    return this.fb.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      recaptcha: ['', [Validators.required]]
    });
  }

  getName(name: string) {
    return this.loginJson?.find(x => x.Nombre == name)?.Valor
  }

  loginFacebook() { }

  async onSubmit() {
    this.submit = true;
    if (this.LoginForm.valid) {
      // Realiza acciones adicionales con los datos del formulario
      let respuesta: any = null;
      this.load.cargar(true)
      await this.expressService.logginUser(this.LoginForm.get('email')?.value.toLowerCase(), this.encrypt(this.LoginForm.get('password')?.value))
        .then((resp) => {
          // Validar cual fue la respuesta del api, verificar si la cuenta esta verificada o no, para manejar de mejor manera los errores y estados de la cuenta
          // console.log(resp);
          if (resp?.accessToken !== null) {
            respuesta = resp
          }
        }).catch((error) => {
          console.error(error);
          // console.log(error.error.message);
          if (error.error.message == "contraseña invalida") {
            this.load.cargar(false);
            this.openDialog('Credenciales erroneas', '');
            this.LoginForm.reset();
            this.submit = false
          }
          else if (error.error.message == "usuario no encontrado") {
            this.load.cargar(false);
            this.openDialog('Usuario no registrado', '', 'registro');
          }
          localStorage.setItem("logged", JSON.stringify({ login: false }));
          if (error.error?.message == 'usuario no encontrado :)') {
            console.log('Correo no registrado');
            this.openDialog('Correo no registrado', '')
            this.load.cargar(false)
          } else if (error.error?.message == 'contraseña invalida!') {
            console.log('Contraseña erronea');
            this.openDialog('Contraseña erronea', '')
            this.load.cargar(false)
          }
        })

      if (respuesta !== null) {
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem("userLogged")
          await this.sincronizarCarro(respuesta.user.correoElectronico)
          await this.sincronizarfavoritos(respuesta.user)

          localStorage.setItem("logged", JSON.stringify({ login: true }));
          this.tokenStorage.saveToken(respuesta.accessToken);
          this.load.cargar(false)
        }
        this.openDialog('¡Bienvenid@ a su cuenta en Kevin´s Joyeros!', 'Gracias por preferirnos', 'home');
      }
    } else {
      // Realiza acciones si el formulario no es válido
      this.openDialog('Todos los campos son obligatorios', '');
    }
  }

  marcaParaSubir(local: any) {
    return local.map((item: any) => ({
      ...item,
      fromSecondArray: true
    }));
  }

  async sincronizarCarro(correo: string) {
    let itemParaSubir: ItemCar[] = []
    await this.expressService.getCarroCompras(correo).then(res => {
      if (localStorage.getItem("shoppingCart") == null) {
        localStorage.setItem("shoppingCart", JSON.stringify(res.carro));
      } else {
        const carroLocal = JSON.parse(localStorage.getItem("shoppingCart") || "")
        const array2Marked = this.marcaParaSubir(carroLocal);
        // console.log(array2Marked);
        const combinedArray = [...res.carro, ...array2Marked];
        // Eliminar duplicados basados en idProducto y tallaId
        const uniqueArray = combinedArray.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.idProducto === item.idProducto && t.tallaId === item.tallaId
          ))
        );
        // console.log(uniqueArray);
        const itemParaSubirMarcados = uniqueArray.filter((item: any) => item.fromSecondArray);
        itemParaSubir = itemParaSubirMarcados.map(({ fromSecondArray, ...rest }) => rest);
        localStorage.removeItem("shoppingCart");
        localStorage.setItem("shoppingCart", JSON.stringify(uniqueArray));
        // console.log(itemParaSubir);
      }
    }).catch(error => {
      if (localStorage.getItem("shoppingCart") !== undefined) {
        itemParaSubir = JSON.parse(localStorage.getItem("shoppingCart") || "")
        // console.log(itemParaSubir);
      }
    })
    // console.log(itemParaSubir);
    if (itemParaSubir.length > 0) {
      for (let i = 0; i < itemParaSubir.length; i++) {
        this.expressService.añadirItemCarrito(this.LoginForm.get('email')?.value.toLowerCase(), itemParaSubir[i]).then(ok => {
          // console.log("subio", itemParaSubir[i]);
        }).catch(error => {
          console.log("error al cargar", itemParaSubir[i]);
        })
      }
    }

  }

  sincronizarfavoritos(userLog: userlogged) {
    // console.log(userLog);
    if (localStorage.getItem("user") !== null) {
      // console.log(localStorage.getItem("user"));
      const userLocal = JSON.parse(localStorage.getItem("user") || "")
      // localStorage.removeItem("user");
      // console.log(userLocal);
      const combinedArray = [...userLog.favoritos, ...userLocal.favoritos];
      // Eliminar duplicados basados en idProducto y tallaId
      const uniqueArray = combinedArray.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.idProducto === item.idProducto
        ))
      );
      // console.log(uniqueArray);
      userLog.favoritos = uniqueArray
      localStorage.setItem("userLogged", JSON.stringify(userLog));
      // console.log(userLog);
      this.expressService.editFavCarrito(userLog)
    } else {
      localStorage.setItem("userLogged", JSON.stringify(userLog));
    }
  }

  isHide() {
    this.hide = !this.hide;
    return false;
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, environment.SECRETKEY).toString();
  }

  redirectToRegister() {
    this.router.navigate(['/registro']);
  }

  // Login con google
  handleLogin(resp: any) {
    if (resp) {
      // codigo de token
      console.log(resp);

      this.idCienteGoogle = resp.clientId
      const payLoad = this.decodeToken(resp.credential);

      this.loginSocial.idCienteGoogle = this.idCienteGoogle
      this.loginSocial.nombre = payLoad.given_name
      this.loginSocial.apellido = payLoad.family_name
      this.loginSocial.correoElectronico = payLoad.email

      console.log(payLoad);

      this.expressService.logginUserSocial(this.loginSocial.correoElectronico.toLowerCase(), resp.credential, false, true, this.loginSocial)
        .subscribe(resp => {
          if (resp.status) {
            if (typeof localStorage !== 'undefined') {
              if (localStorage.getItem("userLogged") != undefined) {
                const infoLocal = JSON.parse(localStorage.getItem("userLogged") || "")
                resp.user.favoritos = this.userService.combinarArraysFav(resp.user.favoritos, infoLocal.favoritos);
              }
              localStorage.setItem("userLogged", JSON.stringify(resp.user));
              this.tokenStorage.saveToken(resp.accessToken);
            }
            this.openDialog('¡Bienvenid@ a su cuenta en Kevin´s Joyeros!', 'Gracias por preferirnos')
          }
        })


      // Tokeb de sesion de inicio
      // sessionStorage.setItem("loggedInUser", JSON.stringify(payLoad))

      // this.router.navigate(['browse'])
    }
  }

  private decodeToken(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }

  openDialog(titulo: string, texto: string, despues: string = '') {
    this.dialog.open(PopupCorreoRegistroComponent, {
      data: {
        titulo: titulo,
        texto: texto,
        boton: null,
        img: 'https://qastorageorafa.blob.core.windows.net/generales/diamante-brillos.png'
      }
    }).afterClosed().subscribe(res => {
      if (despues !== '') {
        this.router.navigate(['/' + despues]).then(() => {
          window.location.reload();
          window.scrollTo(0, 0)
        });
      }
    });
  }
}
