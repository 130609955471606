import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, inject } from '@angular/core';
import { CardProductComponent } from 'libreria-tienda/shared/card-product/card-product.component';
import { EstadosFiltrosService, GetProductsByFilterNameCommand, LoadingService, ManejadorEventosService, Product, SioServicesService, filtroCadena } from 'libreria-tienda';
import { MatGridListModule } from '@angular/material/grid-list';
import { PaginadorComponent } from 'libreria-tienda/shared/paginador/paginador.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-grid-catalogo',
  standalone: true,
  imports: [CommonModule, CardProductComponent, MatGridListModule, PaginadorComponent],
  templateUrl: './grid-catalogo.component.html',
  styleUrl: './grid-catalogo.component.css'
})
export class GridCatalogoComponent implements OnInit, AfterViewInit {

  clase = "wrapper-product";
  cant_columnas: number;
  posicion: number = 0;
  pagina: number;
  cargas: number = 0;
  columnasSele: string;
  filtroCadena: filtroCadena;
  hoveredIndex: number | null = null;

  contadorConsultas: number = 0

  @Input() categoria: any;
  @Input() busqueda: any;
  @Input() set columnas(value: string) {
    this.cant_columnas = value == 'wrapper-product2' ? 50 : value == 'wrapper-product3' ? 60 : 40;
    this.columnasSele = value
  };
  _filtros: any[] = [];
  _max: number = 0;
  _min: number = 0;

  @Output() noEncontrado = new EventEmitter<string>();

  public products: Product[] = new Array<Product>();
  public productsAmostrar: Product[] = new Array<Product>();

  getProductsByFilterNameCommand: GetProductsByFilterNameCommand;

  public estadosFiltrosService = inject(EstadosFiltrosService)
  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)
  public manejadorEventos = inject(ManejadorEventosService)
  public router = inject(Router)

  private subscriptionFiltrosAplicados: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute) { }

  ngOnInit() {
    this.pagina = 1;
    this.posicion = 0;
    if (typeof localStorage !== undefined) {
      localStorage.removeItem("categoria");
      localStorage.removeItem("filtros");
      if (localStorage.getItem("posicion") != undefined) {
        var posicion = JSON.parse(localStorage.getItem("posicion") || "")
        // console.log(posicion);
        if (this.categoria == posicion.codigo) {
          this.pagina = posicion.pagina
          this.posicion = posicion.y
        }
        // console.log(this.posicion);
      } else {
        this.pagina = 1
        this.posicion = 0
      }

      // if (this.busqueda === 0) {
      //   this.route.queryParams.subscribe(params => {
      //     if (params['filtros'] == undefined && params['min'] == undefined) {
      //       // this.loadProductsDos(this.pagina, false, this._filtros);
      //     }
      //   });
      // } else {
      //   this.productsByfilterString(1)
      // }

    } else {
      this.pagina = 1
    }

    this.subscriptionFiltrosAplicados = this.estadosFiltrosService.filtrosAplicados$.subscribe(value => {
      if (value.filtros !== this._filtros || value.precioMax !== this._max || value.precioMin !== this._min) {
        this.pagina = 1
        this.loadProductsDos(this.pagina, false, value.filtros, value.precioMin, value.precioMax);
        this._filtros = value.filtros;
        this._max = value.precioMax;
        this._min = value.precioMax;
      }
    });


  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // console.log(this.posicion);
      window.scrollTo(0, this.posicion);
      localStorage.removeItem("posicion")
      this.posicion = 0;
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriptionFiltrosAplicados.unsubscribe();
  }

  // async productsByfilterString(i: number, cantidad: number = 15) {
  //   this.filtroCadena = {
  //     input: this.busqueda, PageNumber: i, PageSize: cantidad, PrecioMaximo: 100000000, PrecioMinimo: 0
  //   };
  //   const res: any = await this.sioservice.GetProductosBystring(this.filtroCadena).toPromise();
  //   if (res) {
  //     console.log(res);
  //     this.products = [...[]];
  //     this.products = this.products.concat(this.inicializarProducts(res.data));
  //     this.products = this.ErrorImagen(this.eliminarDuplicados(this.products));
  //     this.load.cargar(false);
  //     if (this.products.length <= this.cant_columnas * this.pagina) {
  //       this.loadProductsByfilter(this.pagina);
  //     }
  //   }
  // }

  loadProductsDos(i: number, noEncontrad: boolean = false, filtros: any[] = [], precioMin: number = 0, precioMax: number = 0, categoria: string = this.categoria) {
    if (filtros.length > 0) {
      // console.log('guardar filtros');
      localStorage.setItem("filtros", JSON.stringify({ 'filtros': filtros }));
    }
    this.load.cargar(true)
    this.sioservice.getArticulosPaginados(categoria, this.cant_columnas, i, filtros, precioMin, precioMax).then((resultado) => {
      if (resultado.exitoso) {
        this.pagina = i
        this.products = [...[]];
        this.products = this.products.concat(this.inicializarProducts(resultado.valor));
        // console.log(this.products.length);
        if (this.products.length == 0) {
          // Acá va la logica de cuando el menú no retorna articulos
          // console.log('entra no encontrado');
          this.noEncontrado.emit('noEncontrado');
          // this.loadProductsDos(i, filtros, categoria + 'respaldo')
          if (this.contadorConsultas < 3) {
            this.contadorConsultas++;
            // this.loadProductsDos(this.pagina, true)
          }
          this.load.cargar(false);
        } else {
          this.noEncontrado.emit('siEncontrado');
          this.load.cargar(false);
        }
        if (noEncontrad) {
          this.noEncontrado.emit('noEncontrado');
        }
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  inicializarProducts(data: any) {
    let aux2: Product[] = [];
    for (let index = 0; index < data.length; index++) {
      let aux: Product = {
        cantidad: data[index].cantidad,
        descripcionWeb: data[index].descripcionWeb,
        nombreComercial: data[index].nombreComercial,
        esGIA: data[index].esGIA,
        giA_PrecioMinimo: data[index].giA_PrecioMinimo,
        giA_PrecioMaximo: data[index].giA_PrecioMaximo,
        idProducto: data[index].idProducto,
        imagen: data[index].imagen,
        manejaTalla: data[index].manejaTalla,
        porcentaje: data[index].porcentaje,
        precioAutorizado: data[index].precioAutorizado,
        precioFull: data[index].precioFull,
        precioMaximo: data[index].precioMaximo,
        precioMinimo: data[index].precioMinimo,
        referenciaProveedor: data[index].referenciaProveedor,
        totalRegistros: data[index].totalRegistros,
        errorImagen: false,
        pesoPiedraCentral: data[index].pesoPiedraCentral,
        tipoProducto: this.linea(data[index].idLinea, data[index].esGIA)
      }
      aux2.push(aux)
    }
    // this.load.cargar(false)
    return aux2
  }

  linea(id: string, gia: boolean): string {
    switch (id) {
      case '41':
        if (gia) {
          return 'Gia';
        }
        return 'Anillo';
      case '31':
        if (gia) {
          return 'Gia';
        }
        return 'Anillo';
      case '01':
        return 'Anillo';
      case '03':
        return 'Anillo';
      case '28':
        return 'Venezia';
      default:
        return 'Normal';
    }
  }

  // async loadProductsByfilter(i: number, cantidad: number = 450) {
  //   this.load.cargar(true)
  //   this.filtroCadena = {
  //     input: this.busqueda, PageNumber: i, PageSize: cantidad, PrecioMaximo: 100000000, PrecioMinimo: 0
  //   };
  //   const res: any = await this.sioservice.GetProductosBystring(this.filtroCadena).toPromise();
  //   if (res) {
  //     this.products = this.products.concat(this.inicializarProducts(res.data));
  //     this.products = this.ErrorImagen(this.eliminarDuplicados(this.products));
  //     if (this.products.length <= this.cant_columnas * this.pagina) {
  //       this.loadProductsDos(this.pagina + 1);
  //     } else {
  //       this.productsAmostrar = this.products.slice(this.cant_columnas * (this.pagina - 1), this.cant_columnas * this.pagina);
  //       this.cargas++;
  //       this.load.cargar(false);
  //     }
  //   }
  // }

  eliminarDuplicados(array: Product[]): Product[] {
    array = array.filter((producto, index, self) =>
      index === self.findIndex((p) => p.idProducto === producto.idProducto)
    );
    return array
  }

  ErrorImagen(arreglo: Product[]): Product[] {
    if (isPlatformBrowser(this.platformId)) {
      for (let i = 0; i < arreglo.length; i++) {
        const img = new Image();
        img.src = arreglo[i].imagen;
        img.onerror = () => {
          arreglo[i].errorImagen = true
        };
      }
    }
    return arreglo
  }

  paginaChange(e: any) {
    console.log(e);
    this.load.cargar(true)
    this.loadProductsDos(e, false, this._filtros)
  }

  onHover(index: number): void {
    this.hoveredIndex = index;
  }

  onLeave(): void {
    this.hoveredIndex = null;
  }

}
