declare var google: any;
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';



import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import * as bcrypt from 'bcryptjs';


import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { CommonLista, ExpressService, JsonTextService, LoadingService, SioServicesService, TextResponse, TokenStorageService, User, UserService } from 'libreria-tienda';
import { MatDialog } from '@angular/material/dialog';
import { PopupCorreoRegistroComponent } from 'libreria-tienda/shared/popup-correo-registro/popup-correo-registro.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-registro',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,

  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.KEYGOOGLERECAPTCHA,
      } as RecaptchaSettings,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
  templateUrl: './registro.component.html',
  styleUrl: './registro.component.css'
})
export class RegistroComponent implements OnInit {

  loginSocial: User = new User();
  idCienteGoogle: string;

  router = inject(Router)
  tokenStorage = inject(TokenStorageService)
  userService = inject(UserService)
  jsonService = inject(JsonTextService)
  sioService = inject(SioServicesService)
  expressService = inject(ExpressService)
  load = inject(LoadingService)
  jsonCreate: TextResponse[]

  RegisterForm: FormGroup;

  loggedIn: boolean = false
  checked: boolean = false;
  hide: boolean = true;
  hide2: boolean = true;
  equal: boolean = false;
  equalDocument: boolean = false;
  errorP: boolean = false;

  user: User = new User();;

  optionSelected: string;

  listDocuments: CommonLista[]

  constructor(private fb: FormBuilder, private _adapter: DateAdapter<any>, public dialog: MatDialog) {
    this._adapter.setLocale('es-Es');
    this.RegisterForm = this.initForm()
    this.RegisterForm.addValidators(
      this.matchValidator(this.RegisterForm.get('password'), this.RegisterForm.get('passwordConfirm'), true),
    );
    this.RegisterForm.addValidators(
      this.matchValidator(this.RegisterForm.get('numeroDocumento'), this.RegisterForm.get('numeroDocumentoConfirm'), false)
    );
  }

  ngOnInit(): void {
    this.jsonService.downloadJson(environment.URLJSONCREATE).subscribe(res => {
      this.jsonCreate = res;
    });
    this.getTypeOfDocumento();

    google.accounts.id.initialize({
      // client_id: '798742616400-20hbsn4oi880ah3tjt9mkj2buduoi3t9.apps.googleusercontent.com',
      // client_id: '301237490382-tg8m3reommpu1uuoiqdpp455qraub66g.apps.googleusercontent.com',
      client_id: environment.KEYGOOGLECLIENT,
      callback: (resp: any) => {
        this.handleLogin(resp)
      }
    });

    google.accounts.id.renderButton(document.getElementById("google-btn"), {
      theme: 'filled_black',
      size: 'large',
      shape: 'circle',
      width: 250
    })
  }

  getName(name: string) {
    return this.jsonCreate?.find(x => x.Nombre == name)?.Valor
  }

  getTypeOfDocumento() {
    this.sioService.GetListaPorCodigo("TPDOC").subscribe(list => {
      this.listDocuments = list
      this.load.cargar(false)
    })
  }

  isHide(boton: string) {
    if (boton == "boton1")
      this.hide = !this.hide;
    else
      this.hide2 = !this.hide2;
    return false;
  }

  initForm(): FormGroup {
    // Inicializa el formulario y define los controles
    return this.fb.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      tipoDocumento: ['', Validators.required],
      numeroDocumento: ['', [Validators.required]],
      numeroDocumentoConfirm: ['', [Validators.required]],
      celular: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!.-_]).{8,}$/)]],
      passwordConfirm: ['', [Validators.required]],
      fechaNacimiento: [''],
      recaptcha: ['', Validators.required],
      terminos: ['', Validators.required]
    });
  }

  document(event: any) {
    this.RegisterForm.get("tipoDocumento")?.setValue(event.value.codLista)
  }

  saveUser() {
    if (this.RegisterForm.valid) {

      // console.log('Formulario válido', this.RegisterForm.value);

      this.user.nombre = this.RegisterForm.get("nombre")?.value;
      this.user.apellido = this.RegisterForm.get("apellido")?.value;
      this.user.tipoDocumento = this.RegisterForm.get("tipoDocumento")?.value;
      this.user.numeroDocumento = this.RegisterForm.get("numeroDocumento")?.value;
      this.user.correoElectronico = this.RegisterForm.get("email")?.value.toLowerCase();
      this.user.password = this.encriptar(this.RegisterForm.get("password")?.value)
      this.user.fechaNacimiento = this.RegisterForm.get("fechaNacimiento")?.value;
      this.user.celular = this.RegisterForm.get("celular")?.value;
      this.user.idCienteGoogle = "";
      this.user.idCienteFacebook = "";

      this.expressService.insertUser(this.user).subscribe(user => {
        this.openDialog()
      }, error => {
        console.log("No se ha podido registrar")
        window.scroll({ top: 120, behavior: 'smooth' })
        this.errorP = true;
      })
    } else {
      // Realiza acciones si el formulario no es válido
      console.log('Formulario no válido', this.RegisterForm.value);
    }
  }
  encriptar(password: string) {
    return bcrypt.hashSync(password, 10);
  }

  registerFacebook() { }

  donwloadPolitics() {
    window.open(environment.URLPDFTERMINOS, '_blank');
  }

  matchValidator(
    control: AbstractControl | any,
    controlTwo: AbstractControl | any,
    isPassword: boolean
  ): ValidatorFn {
    return () => {
      if (isPassword) {
        if (control.value !== controlTwo.value) {
          this.equal = false
          return { match_error: 'Value does not match' };
        } else {
          this.equal = true
          return null;
        }
      } else {
        if (control.value !== controlTwo.value) {
          this.equalDocument = false
          return { match_error: 'Value does not match' };
        } else {
          this.equalDocument = true
          return null;
        }
      }
    };
  }

  openDialog() {
    this.dialog.open(PopupCorreoRegistroComponent, {
      data: {
        titulo: '¡Se ha creado su cuenta en Kevin´s Joyeros!',
        texto: 'Por favor revise su correo electrónico y active su cuenta.',
        boton: 'Iniciar sesión',
        img:'https://qastorageorafa.blob.core.windows.net/generales/diamante-brillos.png'
      }
    });
  }

  // Login con google
  handleLogin(resp: any) {
    if (resp) {
      // codigo de token
      console.log(resp);

      this.idCienteGoogle = resp.clientId
      const payLoad = this.decodeToken(resp.credential);

      this.loginSocial.idCienteGoogle = this.idCienteGoogle
      this.loginSocial.nombre = payLoad.given_name
      this.loginSocial.apellido = payLoad.family_name
      this.loginSocial.correoElectronico = payLoad.email

      console.log(payLoad);

      this.expressService.logginUserSocial(this.loginSocial.correoElectronico.toLowerCase(), resp.credential, false, true, this.loginSocial)
        .subscribe(resp => {
          if (resp.status) {
            if (typeof localStorage !== 'undefined') {
              if (localStorage.getItem("userLogged") != undefined) {
                const infoLocal = JSON.parse(localStorage.getItem("userLogged") || "")
                resp.user.favoritos = this.userService.combinarArraysFav(resp.user.favoritos, infoLocal.favoritos);
              }
              localStorage.setItem("userLogged", JSON.stringify(resp.user));
              this.tokenStorage.saveToken(resp.accessToken);
            }
            this.openDialog()
            this.router.navigate(['/home']).then(() => {
              window.location.reload();
              window.scrollTo(0, 0)
            });
          }
        })


      // Tokeb de sesion de inicio
      // sessionStorage.setItem("loggedInUser", JSON.stringify(payLoad))

      // this.router.navigate(['browse'])
    }
  }

  private decodeToken(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }

}
