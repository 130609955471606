<div class="contenedor-servicios-center">
  <div class="w-100 contenedor">
    <div class="title-section">
      <span class="title-estilos">{{getName('title')}}</span>
    </div>
    <div class="card-container d-flex-row d-none d-sm-none d-md-none d-lg-block showpc">
      <!-- @for (service of services; track $index) {} -->
      <div class="contenedor-servicios">
        <!-- outlet -->
        <div class="card-service">
          <div class="contenedor-icon">
            <img class="icon-size pb-icon" [src]="getName('icon-outlet')">
          </div>
          <div class="center-v pb-15px">
            <span class="card-title-bold center-v-h">{{getName('outlet')}}</span>
          </div>
          <div class="center-v-start textos">
            <span class="start card-title">{{getName('text-outlet')}}</span>
          </div>
          <div class="center-v point" (click)="navigate('catalogo/sale')">
            <span class="center-v-h card-text pb-1rem-c">{{getName('text-link-outlet')}}</span>
            <mat-icon class="center-v-h">chevron_right</mat-icon>
          </div>
        </div>
        <!-- Certificados -->
        <div class="card-service">
          <div class="contenedor-icon">
            <img class="icon-size pb-icon" [src]="getName('icon-descarga')">
          </div>
          <div class="center-v pb-15px">
            <span class="card-title-bold center-v-h">{{getName('descarga')}}</span>
          </div>
          <div class="center-v-start textos">
            <span class="start card-title">{{getName('text-descarga')}}</span>
          </div>
          <div class="center-v">
            <span class="center-v-h card-text pb-1rem-c">{{getName('text-link-descarga')}}</span>
            <mat-icon class="center-v-h">chevron_right</mat-icon>
          </div>
        </div>
        <!-- Gana Kilates -->
        <div class="card-service">
          <div class="contenedor-icon">
            <img class="icon-size pb-icon" [src]="getName('icon-puntos')">
          </div>
          <div class="center-v pb-15px">
            <span class="card-title-bold center-v-h">{{getName('puntos')}}</span>
          </div>
          <div class="center-v-start textos">
            <span class="start card-title">{{getName('text-puntos')}}</span>
          </div>
          <div class="center-v point" (click)="navigate('gana-kilates')">
            <span class="center-v-h card-text pb-1rem-c">{{getName('text-link-puntos')}}</span>
            <mat-icon class="center-v-h">chevron_right</mat-icon>
          </div>
        </div>
        <!-- Bono Regalo -->
        <div class="card-service">
          <div class="contenedor-icon">
            <img class="icon-size pb-icon" [src]="getName('icon-bono')">
          </div>
          <div class="center-v pb-15px">
            <span class="card-title-bold center-v-h">{{getName('bono')}}</span>
          </div>
          <div class="center-v-start textos">
            <span class="start card-title">{{getName('text-bono')}}</span>
          </div>
          <div class="center-v">
            <span class="center-v-h card-text pb-1rem-c">{{getName('text-link-bono')}}</span>
            <mat-icon class="center-v-h">chevron_right</mat-icon>
          </div>
        </div>
        <!-- compra Oro -->
        <div class="card-service">
          <div class="contenedor-icon">
            <img class="icon-size pb-icon" [src]="getName('icon-compra')">
          </div>
          <div class="center-v pb-15px">
            <span class="card-title-bold center-v-h">{{getName('compra')}}</span>
          </div>
          <div class="center-v-start textos">
            <span class="start card-title">{{getName('text-compra')}}</span>
          </div>
          <div class="center-v links point" (click)="navigate('compra-oro')">
            <span class="center-v-h card-text pb-1rem-c">{{getName('text-link-compra')}}</span>
            <mat-icon class="center-v-h">chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </div>


    <div class="d-block d-sm-block d-md-block d-lg-none flex-wrap showmob">
      <!-- <div *ngFor="let ser of services">
        <div class="card pl-pr-1rem">
          <span [ngClass]="ser.icon" class="icon-size"></span>
          <div class="d-flex-column">
            <span class="card-title">{{ser.title1}}</span>
            <span class="card-title-bold">{{ser.title2}}</span>
          </div>
          <span class="card-text underline color-black">{{ser.link}}</span>
        </div>
      </div> -->
    </div>
  </div>
</div>
