<!-- <lib-loader /> -->
<lib-header-mobile (evento)="manejarEvento($event)" class="top" />
<div class="pb-mobile">
  <lib-side-bar #side_bar>
    <div mobile class="contenido">
      <ng-content select="[contenido]"></ng-content>
    </div>
  </lib-side-bar>
  <lib-footer></lib-footer>
</div>
<lib-menu-inferior-mobile (evento)="manejarEvento($event)" />
