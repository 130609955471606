import { CommonModule } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-gia',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './gia.component.html',
  styleUrl: './gia.component.css'
})
export class GiaComponent {

  // ver index.html - https://qastorageorafa.blob.core.windows.net/js-page/gia.js

  posicion_diamante: number[] = [-5575, -5280, -4990, -4700, -4410, -4114, -3818, -3525, -3230, -2938, -2644, -2350, -2058, -1764, -1468, -1174, -880, -585, -292, 0]
  mov_diamante: string = 'left top 0px'
  left_top: string = 'left top '
  top: number = -110 - 144

  altura_total: number
  altura_animacion: number

  // bg = 'blue';

  propiedades: any;

  iscel: boolean = false;
  jsonGia: TextResponse[]
  jsonService = inject(JsonTextService)
  configService = inject(ConfigService)

  constructor(private router: Router) {
    this.propiedades = propiedades;
  }

  ngOnInit(): void {
    if (typeof screen !== 'undefined') {
      if (screen.width > 600) {
        this.iscel = true
      }
    }
    this.jsonService.downloadJson(this.configService._config.URLJSONGIA).subscribe(res => {
      this.jsonGia = res
    })

  }

  routing() {
    console.log("clic routing")
    this.router.navigateByUrl("/catalogue/GIA")
  }

  getName(name: string) {
    return this.jsonGia?.find(x => x.Nombre == name)?.Valor
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const elemento = document.querySelector('.diamanate-moviento') as HTMLElement;
    const rect = elemento.getBoundingClientRect();
    this.altura_total = window.innerHeight
    this.altura_animacion = window.innerHeight - 250

    var porcentaje = Math.round(((rect.bottom - (window.innerHeight - 285)) / 250) * 100)

    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
      if (rect.bottom <= window.innerHeight - 250) {
        this.mov_diamante = this.left_top + this.posicion_diamante[0] + 'px';
      } else {
        this.mov_diamante = this.left_top + this.posicion_diamante[Math.round(porcentaje / 5)] + 'px';
        this.top = -110 - (144 * (porcentaje / 100))
      }
    } else {
      this.mov_diamante = this.left_top + this.posicion_diamante[19] + 'px';
    }
  }

  navigate(url: string) {
    this.router.navigateByUrl(`/${url}`)
  }

}
