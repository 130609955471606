<div class="d-block d-sm-block d-md-block d-lg-none menu-mobile align-center w-100">
  <div class="d-flex-row space-around w-100 plr-2-4rem">
    <div>
      <span (click)="routing('login')" *ngIf="iniciales == '-'" class="icon-User icon-color-b font-size-22"></span>
      <span (click)="routing('profile-main')" *ngIf="iniciales != '-'" class="userlogged">{{iniciales}}</span>
    </div>
    <span (click)="openWa()" class="icon-Whatsapp wa-color font-size-22 icon-color-b"></span>
    <!-- <div class="d-flex" *ngIf="section != 0"> -->
    <!-- <span (click)="openFilters()" class="icon-filtros color-icon-wh color-icon-h pl-1 font-size-18 icon-color-b"></span> -->
    <!-- <span (click)="openFilters()" class="icon-Filtros_icono  font-size-18 icon-color-b"></span>
          <span *ngIf="filtersActiveTotal != 0">({{filtersActiveTotal}})</span>
      </div> -->
      <div class="flex-relative" (click)="goCart()">
    @if (rutaActual=='/shopping-cart') {
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
    }@else {
        <span class="icon-shoppingCart font-size-22 icon-color-b"></span>
        <span *ngIf="totalProducts > 0" class="numberProducts">{{totalProducts}}</span>
      }
    </div>
  </div>

</div>
