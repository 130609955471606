<mat-drawer-container autosize hasBackdrop="true">
  <mat-drawer #drawerDerecha [ngClass]="{'top':dispositivoService.isDesktop, 'top-mobile':dispositivoService.isMobile}" class="right-w" mode="over" position="end">
    <div class="close">
      <span class="close-b" (click)="drawerDerecha.toggle(); drawerIzquierda.close();">X</span>
    </div>
    <lib-cart-right />
  </mat-drawer>
  <mat-drawer #drawerIzquierda [ngClass]="{'top':dispositivoService.isDesktop, 'top-mobile':dispositivoService.isMobile}" class="left-w" mode="over" position="start">
    <div class="close">
      <span class="close-b" (click)="drawerIzquierda.toggle(); drawerDerecha.close();">X</span>
    </div>
    <!-- <lib-menu-izq /> -->
  </mat-drawer>
  <ng-content select="[escritorio]"></ng-content>
  <ng-content select="[mobile]"></ng-content>
</mat-drawer-container>
