import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService, ExpressService, Gia, InfoGia, JsonTextService, ShoppingCartService, TextResponse, caracteristicas, infoVenecia, items } from 'libreria-tienda';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';
import { EtiquetaDescuentoComponent } from 'libreria-tienda/shared/etiqueta-descuento/etiqueta-descuento.component';
import { ReferenciasComponent } from 'libreria-tienda/shared/referencias/referencias.component';
import { TallasComponent } from 'libreria-tienda/shared/tallas/tallas.component';
import { Tallascantidad } from 'libreria-tienda/src/lib/interfaces/cart';

@Component({
  selector: 'lib-detalles-producto-gia',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule, TallasComponent, FavoritoCorazonComponent, ReactiveFormsModule, MatFormFieldModule, EtiquetaDescuentoComponent, ReferenciasComponent],
  templateUrl: './detalles-producto-gia.component.html',
  styleUrl: './detalles-producto-gia.component.css'
})
export class DetallesProductoGiaComponent implements OnInit {

  @Input() item: Gia[];

  caracteristicas: caracteristicas[];
  caracteristicasItem: caracteristicas[] = [];

  giaSeleccionado: boolean = false;
  infoGiaSeleccionado: Gia = new Gia();

  tallas: Tallascantidad[] = []
  referencias: Gia[] = [];

  selectSize: boolean = true;
  open: boolean = false;
  submited: boolean = false;
  unico: boolean = false;

  tallaSelec: boolean = false;
  referenciaSelec: boolean = false;

  jsonDetalle: TextResponse[]

  precioMinimo: number;

  referencesGia: InfoGia[]

  referenciaId: string = "0";

  canti: number = 0;

  pesoPiedra: number;

  caracteristicasFull: any;

  caracFull: boolean = false;
  catafull: boolean = false;

  reemplazos: any = {
    "am": "amarillo",
    "bl": "blanco",
    "rj": "rosa"
  };

  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public cartService = inject(ShoppingCartService)
  public expressService = inject(ExpressService)

  constructor(private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    var idGia: string;
    this.route.queryParamMap.subscribe(params => {
      this.giaSeleccionado = (params.get('producto') ?? false) !== '';
      this.pesoPiedra = parseFloat(params.get('pesoPiedra') ?? '0');
      if (this.giaSeleccionado) {
        idGia = params.get('producto') ?? '';
      }
    })
    if (this.item) {
      const tallasUnicas = new Set<string>();
      this.item.forEach(element => {
        if (!tallasUnicas.has(element.talla)) {
          tallasUnicas.add(element.talla);
          let aux: Tallascantidad = { talla: element.talla, cantidad: 1, tallaId: element.tallaId };
          this.tallas.push(aux);
        } else {
          const index = this.tallas.findIndex(t => t.talla === element.talla);
          if (index !== -1) {
            this.tallas[index].cantidad++;
          }
        }
      });
      if (this.item && this.giaSeleccionado) {
        const index = this.item.findIndex(item => item.idProducto === idGia);
        if (index !== -1) {
          // El elemento fue encontrado
          this.infoGiaSeleccionado = this.item[index];
          this.tallaGia(index)
        }
        // this.infoGiaSeleccionado = this.item.find(item => item.idProducto === idGia) || new Gia();
        this.infoGiaSeleccionado = this.item[index];
      }
    }

    this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
      if (res !== undefined) {
        this.jsonDetalle = res;
        this.caracteristicasFull = this.getName("caracteristicas")
        // console.log(this.caracteristicasFull);
        this.caracFull = true;
        if (this.catafull) {
          this.caracteristicasMostrar();
        }
      }

    }).closed
    if (this.item !== undefined) {
      this.precioMinimo = this.item[this.item.length - 1].precioFull;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['item']) {
      // console.log('ngOnChanges:', this.catalogo);
      if (this.item != undefined) {
        this.catafull = true
        console.log('Catalogo en ngOnChanges:', this.item);
        if (this.caracFull) {
          this.caracteristicasMostrar();
        }
      }
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  onPanelClosed() {
    this.open = false
  }

  onPanelOpened() {
    this.open = true;
  }

  talla(i: number) {
    this.tallaSelec = true;
    this.referencias = this.item.filter(item => item.talla === this.tallas[i].talla);
    // console.log(this.referencias);
  }
  tallaGia(i: number) {
    this.tallaSelec = true;
    this.referencias = this.item.filter(item => item.talla === this.item[i].talla);
    // console.log(this.referencias);
  }

  agregarCarrito() {
    this.submited = true
    // if (this.infoGiaSeleccionado.talla != '-') {
    //   if (this.canti > 1) {
    //     this.unico = false
    //     // this.cartService.addItemCart(this.item)
    //   } else {
    this.unico = true
    //   }
    // }
  }

  donwloadManual() {
    this.expressService.getPdf("Manual de tallas").subscribe(res => {
      window.open(this.configService._config.URLPDFTALLAS + res.file, '_blank');
    })
  }

  donwloadPolitics() {
    window.open(this.configService._config.URLPDFTERMINOS, '_blank');
  }

  openChat() {
    console.log('Open Wa');
  }

  referencia(i: number) {
    this.referenciaSelec = true
    var filtro;
    this.route.queryParamMap.subscribe(params => {
      filtro = (params.get('filtro') ?? 0);
    })
    console.log(i);
    this.router.navigate(['/detalle'], { queryParams: { filtro: filtro, catalogo: this.referencias[i].categoria, producto: this.referencias[i].idProducto, GIA: true, tipoProducto: 'Gia', pesoPiedra: this.pesoPiedra, referenciaProveedor: this.referencias[i].ReferenciaProveedor } }).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }

  caracteristicasMostrar() {
    // console.log(this.item.catalogo);

    // console.log(this.caracteristicasFull[this.item.catalogo]);
    // console.log(this.item.caracteristicas);

    this.caracteristicas = this.caracteristicasFull['matrimonio']

    // console.log(this.item[0].caracteristicas);

    // console.log(this.caracteristicas);

    // console.log(JSON.parse(this.item[0].caracteristicas)['Descuento']);




    this.caracteristicasItem = this.caracteristicas
      .map((caractWeb: any) => {
        const valor = JSON.parse(this.item[0].caracteristicas)[caractWeb.db];
        return valor !== undefined ? { ...caractWeb, valor } : null;
      })
      .filter((item: any) => item !== null);

    // console.log(this.caracteristicasItem);

    // console.log(this.caracteristicasFull["dijes"]);

    const index = this.caracteristicasItem.findIndex(obj => obj.web === "Metal");

    if (index !== -1) {
      // console.log(this.caracteristicasItem[index].valor);
      switch (this.caracteristicasItem[index].valor) {
        case "ACERO":
          break;
        case "PLATA":
          this.caracteristicasItem[index].valor = "Plata Ley 925"
          break;

        default:
          this.caracteristicasItem[index].valor = "ORO " + this.reemplazarFrase(this.caracteristicasItem[index].valor) + " 18 kt"
          break;
      }
    }
  }

  reemplazarFrase(frase: string) {
    // Encontrar la parte que contiene las siglas (después de "2 tonos ")
    let partesFrase = frase.split(" ");
    // console.log(partesFrase[0]);
    if (partesFrase[0] == '2') {
      let parteSiglas = partesFrase[2]; // Esto es "Am-BL"
      // console.log(parteSiglas);

      // Separar las siglas unidas por guiones
      let siglas = parteSiglas.split("-");

      // Reemplazar cada sigla con el valor correcto
      let siglasReemplazadas = siglas.map(sigla => {
        let siglaLowerCase = sigla.toLowerCase();
        return this.reemplazos[siglaLowerCase] || sigla; // Si no encuentra reemplazo, deja la palabra igual
      });

      // Unir las siglas reemplazadas con guiones
      let parteReemplazada = siglasReemplazadas.join(" - ");

      // Volver a armar la frase completa
      partesFrase[2] = parteReemplazada;
      return partesFrase.join(" ");
    } else if (frase == 'ROJO') {
      return 'rosa'
    } else {
      return frase
    }
  }

}
