<div class="contenedor">
  <div class="contenedor-titulo">
    <h2>Resumen de tu Compra</h2>
    <span (click)="navigate('shopping-cart')" class="pointer">
      <a>
        <span class="icon-shoppingCart icon-size"></span>
      </a>
    </span>
  </div>
  <div class="articulos">
    @for (item of listItemsCompra; track $index) {
    <div class="contendor-item">
      <img [src]="item.imagen" alt="">
      <div class="datos-item">
        <h3>Ref. {{item.idProducto}}</h3>
        <span class="full">${{item.precioFull | number}}</span>
        <span class="autorizado">${{item.precioAutorizado | number}}</span>
      </div>
    </div>
    }
  </div>
  <div class="contendro-cupon">
    <input type="text" [value]="cupon" placeholder="Ingresa tu código">
    <button class="boton-bono">Aplicar</button>
  </div>
  <div class="contenedor-valores">
    <div class="valores">
      <span>Subtotal</span>
      <span>{{totalFull() | number}}</span>
    </div>
    <div class="valores">
      <span>Descuento</span>
      <span class="descuento"> -{{totalFull() - totalAutorizado() | number}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="valores">
      <span>Total</span>
      <span> {{totalAutorizado() | number}}</span>
    </div>
    <div class="info">
      El costo de envío no está incluido en el precio
    </div>
  </div>
  <button class="pago" (click)="solicitudPago()">Realizar Pago</button>
</div>
