<lib-menu-catalogo [categoria]="filtro" (changeColumns)="cambioCol($event)" (activeFiltros)="sidenav.toggle()" />
<lib-banners [catalogo]="filtro" [noEncontrado]="noEncontrado" />
<div class="conetenedor-columnas">
  <div class="columna-filtros">
    <lib-side-bar-filtro [codigo]="filtro"/>
  </div>
  <div class="columna-catalogo">
    <div [ngClass]="{'catalogo-init-mobile':dispositivoService.isMobile, 'catalogo-init':!dispositivoService.isMobile}">
      <lib-grid-catalogo [categoria]="filtro" [columnas]="changeColumns" [busqueda]="busqueda" (noEncontrado)="noEncontradoBanner($event)" />
    </div>
  </div>
</div>
