import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ManejadorEventosService, PantallaSaleService } from 'libreria-tienda';

declare var propiedades: any | undefined;

@Component({
  selector: 'lib-menus',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatIconModule],
  templateUrl: './menus.component.html',
  styleUrl: './menus.component.css'
})
export class MenusComponent implements OnInit {
  private manejadorEventos = inject(ManejadorEventosService)

  propiedades: any
  sale: boolean;


  constructor(private router: Router) {
    this.propiedades = propiedades;
  }

  private pantallaSaleService = inject(PantallaSaleService)


  ngOnInit(): void {
    this.pantallaSaleService.estado$.subscribe(valor => {
      this.sale = valor;
    });
  }

  // redirect(filtro: string, catalogo: string) {
  redirect(filtro: string) {
    this.manejadorEventos.lanzarEvento('goCatalogo')
    // this.router.navigate([`/catalogo`], { queryParams: { filtro: filtro, catalogo: catalogo } }).then(() => {
    this.router.navigate([`/catalogo/${filtro}`]).then(() => {
      window.location.reload();
    });
  }

  redirectFiltros(nextParam: string, filtros: string, titulo: string) {
    this.manejadorEventos.lanzarEvento('goCatalogo')
    // this.router.navigate([`/catalogo`], { queryParams: { filtro: filtro, catalogo: catalogo } }).then(() => {
    if (titulo == "Regalos para ...") {
      this.redirect(filtros)
    } else {
      this.router.navigate(['/catalogo', nextParam], {
        queryParams: { filtros: filtros }
      }).then(() => {
        window.location.reload();
      });
    }
  }
}
