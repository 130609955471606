@if (imagenesSinError.length>0) {
<div class="contenedor">
  <div class="contendor-boton izquierda" (click)="menos()">
    <i class="fa-solid fa-chevron-left"></i>
  </div>
  <div class="contendor-img" (click)="detalle()">
    <img [src]="imagenesSinError[count]" alt="" class="size-img" [ngClass]="fadeClass"
      [ngClass]="{'traslucido': tallas[0] !== undefined}">
    @if (producto.esGIA) {
    <img src="https://kevins.com.co/img/gia_logo_sm.png" alt="GIA" class="gia">
    }
    @if (tallas.length>0) {
    <div class="overlay"></div>
    <div class="seleccionador">
      <mat-form-field appearance="fill" class="ancho-selec">
        <mat-select (selectionChange)="tallaSeleccionada($event)" (openedChange)="onSelectOpened($event)"
          placeholder="Selecciona tu talla" required>
          @for (talla of tallas; track talla) {
          <mat-option [value]="talla">
            {{talla.descripcion | titlecase}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    }
  </div>
  <div class="contendor-boton derecha" (click)="mas()">
    <i class="fa-solid fa-chevron-right"></i>
  </div>
</div>
<div class="contenedor-textos">



  <p class="valor ref" (click)="detalle()">Ref. {{producto.idProducto}}</p>

  @if (producto.tipoProducto == 'Venezia') {
    <p class="text">{{producto.nombreComercial == "" ? producto.descripcionWeb :
      producto.nombreComercial}}</p>
    }@else {
    <p class="text">{{producto.descripcionWeb}}</p>
    }


  @if (!producto.esGIA && cantidadMaxima>1) {
  <span class="valor" (click)="detalle()">${{producto.precioAutorizado| number}}</span>
  <button class="add-cart" (click)="consultarTallas()">Añadir al carrito
    <!-- <i class="fa-solid fa-cart-shopping"></i> -->
    <span class="icon-shoppingCart p-top "></span>
  </button>
  }@else {
  <span class="valor" (click)="detalle()"><span class="hasta">Hasta</span> ${{producto.precioAutorizado| number}}</span>
  <button class="add-cart">Contacta a un experto
    <!-- <i class="fa-solid fa-cart-shopping"></i> -->
  </button>
  }
</div>
}
