@if(!errorImgProduct){
<!-- <div [ngClass]="card1" class="card-container"> -->
<div class="contenedor">
  <div class="card-container" [ngClass]="{'favoritos':favoritos}" (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()">
    <lib-favorito-corazon [size]="'heart-size2'" [esGia]="_producto.esGIA" [idProducto]="_producto.idProducto"
      [peso]="_producto.pesoPiedraCentral" [referenciaProveedor]="_producto.referenciaProveedor" class="fav" />
    <lib-etiqueta-descuento-card [descuento]="_producto.porcentaje" class="descuento" />
    @if (!isHovered) {
    <!-- @if (false) { -->
    <div (click)="detalle(); guardarPosicion()">
      <div class="center contenedor-img">
        <img alt="imagenProducto" class="cursor size1" src="{{_producto.imagen}}"
          (error)="manejarErrorDeImagen(_producto.imagen)">
        @if (_producto.esGIA) {
        <img src="https://kevins.com.co/img/gia_logo_sm.png" alt="GIA" [ngClass]="GIA" class="gia">
        }
      </div>

      <div class="contenedor-textos">
        <span class="precio-auto ref">Ref. {{_producto.idProducto}}</span>
        @if (_producto.cantidad+'' !=='0') {
        @if (_producto.tipoProducto == 'Venezia') {
        <p class="descWeb">{{_producto.nombreComercial == "" ? _producto.descripcionWeb :
          _producto.nombreComercial}}</p>
        }@else {
        <p class="descWeb">{{_producto.descripcionWeb}}</p>
        }
        @if (!_producto.esGIA) {
        <span class="precio-full">${{_producto.precioFull | number}}</span>
        <span class="precio-auto">${{_producto.precioAutorizado | number}}</span>
        } @else {
        <span class="precio-gia">
          <span class="desde">Hasta</span>
          ${{_producto.precioFull| number}}
        </span>
        }
        }@else {
        <span class="prices pb-4"> Agotado</span>
        }
      </div>
    </div>
    }@else {
    <div class="carrusel">
      <lib-carrusel-img-card [referencia]="_producto.idProducto" [producto]="_producto" [manejaTalla]="manejaTalla"
        [cantidadMaxima]="cantidadMaximaNumber" (seleccionado)="seleccionado($event)"
        (abiertoSeleccionador)="abierto($event)" />
    </div>
    }
  </div>
</div>
}
