@if (banners) {
<div class="container">
  <div class="wrapper">
    <video oncanplay="this.play()" onloadedmetadata="this.muted = true" width="100%" height="100%" muted loop autoplay
      playsinline class="video-home" [style.background]="propiedades[section]['color']">
    <source [src]="propiedades[section]['video1']" type="video/mp4">
    </video>
    <!-- <iframe width="100%" height="600" src="https://www.youtube.com/embed/swxZy3gDZgg?autoplay=1&loop=1&playlist=swxZy3gDZgg"
      title="YouTube video player" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
  </div>
</div>
}@else {
<div class="container">
  <div class="wrapper-skeleton">
    <ngx-skeleton-loader class="skeleton-img" count="1" appearance="circle" [theme]="{
      'width.%': 80,
      'height.rem': 40,
      'border-radius': '10px'
    }"></ngx-skeleton-loader>
  </div>
</div>
}
