import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Cart, ItemCar, ItemCompra, LoadingService, ShoppingCartService, SioServicesService, filtro, items } from 'libreria-tienda';

@Component({
  selector: 'lib-carrito',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule
  ],
  templateUrl: './carrito.component.html',
  styleUrl: './carrito.component.css'
})
export class CarritoComponent implements OnInit {

  shoppingCart: ItemCar[];
  canti: number;

  tallasFiltro: Map<string, filtro> = new Map<string, filtro>();
  filtroLista: Array<filtro> = new Array<filtro>();
  dataSource = new MatTableDataSource<items>([]);
  filtro: filtro = new filtro();

  @Input() carrito: ItemCompra[] = []
  @Output() indexDelet = new EventEmitter<number>();

  public cartService = inject(ShoppingCartService)
  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)
  public router = inject(Router)

  constructor() { }


  ngOnInit(): void { }

  deleteItem(i: number) {
    // this.carrito.splice(i, 1);
    this.indexDelet.emit(i)
  }
  EditItem(i: number) {
    let aux = this.carrito[i].idProducto;
    this.deleteItem(i);
    this.router.navigate([`/producto/${aux}`], { queryParams: { compra: true } }).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }

  cartLocalStorage() {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem("shoppingCart", JSON.stringify(this.shoppingCart));
    }
  }

  routing(referencia: string) {
    // this.router.navigate([`/detalle/${categoria}/${referencia}`])
  }

  getTotal(i: number) {
    return this.carrito[i].cantidadSeleccionada * this.carrito[i].precioAutorizado
  }

  getTotalSinDescuento(i: number) {
    return this.carrito[i].cantidadSeleccionada * this.carrito[i].precioFull
  }

  handleKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  menos(i: number) {
    if (this.carrito[i].cantidadSeleccionada - 1 > 0) {
      this.carrito[i].cantidadSeleccionada--;
      this.cartService.deleteItemCart(i, 1);
    }
  }

  mas(i: number) {
    if (this.carrito[i].cantidadDisponibleTalla == -1) {
      if (this.carrito[i].cantidadSeleccionada + 1 < this.carrito[i].cantidadDisponibleProducto) {
        this.carrito[i].cantidadSeleccionada++;
        let aux: ItemCar = new ItemCar();
        aux.idProducto = this.carrito[i].idProducto;
        aux.tallaId = parseInt(this.carrito[i].idProducto);
        aux.cantidad = 1;
        aux.imagen = this.carrito[i].imagen;
        aux.maxCantidad = this.carrito[i].cantidadDisponibleProducto;
        aux.descWeb = this.carrito[i].descripcionWeb;
        aux.descTalla = '';
        this.cartService.addItemCart(aux)
      } else {
        console.log('Se supera la cantidad disponible');
      }
    } else {
      if (this.carrito[i].cantidadSeleccionada + 1 <= this.carrito[i].cantidadDisponibleTalla) {
        this.carrito[i].cantidadSeleccionada++;
      } else {
        console.log('Se supera la cantidad disponible');
      }
    }
  }
}
