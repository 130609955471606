@if (favoritos) {
<div class="container text-center top" [ngClass]="{'size-seis-container':columnasSele =='wrapper-product3'}">
  <div class="row align-items-center" [ngClass]="{'size-seis-row':columnasSele =='wrapper-product3'}">
    @for (product of favoritos; track $index) {
    <lib-card-product class="col-12 col-md-3 grilla" [product]="product" [clase]="columnasSele" [pagina]="pagina" [favoritos]="true"
      [ngClass]="{'size-seis':columnasSele =='wrapper-product3','size-cinco':columnasSele =='wrapper-product2'}" />
    }
  </div>
</div>
@if(favoritos.length>0){
<lib-paginador [categoria]="'Favoritos'" [fav]="fav" (paginaOut)="paginaChange($event)" />
}@else {
<div class="center payment-container">
  <h2 class="pt-5 pb-5">No tiene articulos en favoritos </h2>
</div>
}
}
