import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderMobileComponent } from 'libreria-tienda/shared/header/header-mobile/header-mobile.component';
import { LoaderComponent } from 'libreria-tienda/shared/loader/loader.component';
import { SideBarComponent } from 'libreria-tienda/shared/side-bar/side-bar.component';
import { ManejadorEventosService } from 'libreria-tienda';
import { MenuInferiorMobileComponent } from 'libreria-tienda/shared/menu-inferior-mobile/menu-inferior-mobile.component';
import FooterComponent from 'libreria-tienda/shared/footer/footer.component';

@Component({
  selector: 'app-mobile',
  standalone: true,
  imports: [CommonModule, LoaderComponent, HeaderMobileComponent, SideBarComponent, MenuInferiorMobileComponent,FooterComponent],
  templateUrl: './mobile.component.html',
  styleUrl: './mobile.component.css'
})
export class MobileComponent {


  public manejadorEventos = inject(ManejadorEventosService)


  constructor(private router: Router) {
    this.manejadorEventos.main.subscribe(val => {
      // console.log(val);
      this.manejarEvento(val)
    })
  }

  @ViewChild('side_bar') side_bar: SideBarComponent;

  manejarEvento(e: string) {
    const rutaActual = this.router.url
    // console.log(e);
    switch (e) {
      case 'abrirDerecha':
        this.side_bar.abrirDerecha()
        break;
      case 'abrirIzquierda':
        this.side_bar.abrirIzquierda()
        break;
      case 'abrirArriba':
        this.side_bar.abrirArriba()
        break;
      case 'Menu':
        this.side_bar.abrirIzquierda()
        break;
      case 'cerrarIzquierda':
        this.side_bar.cerrarIzquierda()
        break;
      case 'Carrito':
        if (rutaActual != '/shopping-cart') {
          // console.log('dentro if');
          this.side_bar.abrirDerecha()
        }
        break;
      default:
        break;
    }

  }
}
