import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { Anillo, Item, items } from 'libreria-tienda';
import { Tallascantidad } from '../../../../dist/libreria-tienda/lib/interfaces/cart';

@Component({
  selector: 'lib-tallas',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './tallas.component.html',
  styleUrl: './tallas.component.css'
})
export class TallasComponent implements OnInit {

  @Input() tallas: Tallascantidad[]

  @Input() tallaSelectd: string;
  @Input() submited: boolean;

  @Output() ItemData = new EventEmitter<number>();

  tallaSelecionada: Tallascantidad | undefined;

  ngOnInit(): void {
    if (this.tallaSelectd) {
      // console.log(this.tallaSelectd);
      this.tallaSelecionada = this.tallas.find(item => item.talla === this.tallaSelectd);
    }
  }

  talla(e: any) {
    // console.log(e.value);
    this.tallas.forEach((element, i) => {
      if (element.talla == e.value.talla) {
        this.ItemData.emit(i)
      }
    });
  }
}
