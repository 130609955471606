<div class="container-grid">
  <div class="grilla">
    @for (product of products; track $index) {
    <!-- @if ($index<4) { -->
    <div class="unaColumna">
      <lib-card-product [product]="product" [clase]="columnasSele" [pagina]="pagina"
      [isHovered]="hoveredIndex === $index"
      (hover)="onHover($index)"
      (leave)="onLeave()"
      />
    </div>
    <!-- } -->
    }
  </div>
  @if(products.length>0){
  <lib-paginador [categoria]="categoria" (paginaOut)="paginaChange($event)" [filtros]="_filtros" />
  }
</div>
<!-- {{productsAmostrar.length}} -->
