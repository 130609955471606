import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Banner, DispositivoService, ExpressService, FavoritoService, LoadingService, PantallaSaleService, UserService } from 'libreria-tienda';
import { BannersComponent } from 'libreria-tienda/shared/banners/banners.component';
import { GridCatalogoComponent } from 'libreria-tienda/shared/catalogo/grid-catalogo/grid-catalogo.component';
import { MenuCatalogoComponent } from 'libreria-tienda/shared/catalogo/menu-catalogo/menu-catalogo.component';
import { SideBarFiltroComponent } from 'libreria-tienda/shared/side-bar-filtro/side-bar-filtro.component';
@Component({
  selector: 'app-catalogo',
  standalone: true,
  imports: [CommonModule, MenuCatalogoComponent, GridCatalogoComponent, BannersComponent, MatSidenavModule, SideBarFiltroComponent],
  templateUrl: './catalogo.component.html',
  styleUrl: './catalogo.component.css'
})
export class CatalogoComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  private intervaloId: any;

  filtro: any;
  catalago: any;
  busqueda: any;
  changeColumns: any;

  noEncontrado: boolean = false

  reason = '';

  public load = inject(LoadingService)
  public expressService = inject(ExpressService)
  public dispositivoService = inject(DispositivoService)
  public favoritoService = inject(FavoritoService)
  public userService = inject(UserService)
  public pantallaSaleService = inject(PantallaSaleService)

  private route = inject(ActivatedRoute)

  banners: Banner;

  constructor() {
    this.route.queryParamMap.subscribe(params => {
      // this.filtro = (params.get('filtro') ?? 0);
      this.catalago = (params.get('catalogo') ?? 0);
      this.busqueda = (params.get('busqueda') ?? 0);
    })

    this.route.paramMap.subscribe(params => {
      this.filtro = params.get('busqueda');
      // console.log(this.filtro);
      if (this.filtro == 'sale') {
        this.actualizarValor(true)
      } else {
        this.actualizarValor(false)
      }
    });
    this.load.cargar(false)
  }

  async ngOnInit() {
    await this.expressService.getBanners(this.catalago).subscribe(res => {
      this.banners = res.banners[0]
    })
    this.intervaloId = setInterval(() => {
      this.userService.getUser();
      this.favoritoService.guardarFav();
      console.log('guardar');
    }, 15000);

  }

  cambioCol(e: any) {
    console.log(e);
    this.changeColumns = e
  }

  noEncontradoBanner(e: any) {
    // console.log(e);
    if (e == 'noEncontrado') {
      this.noEncontrado = true;
    } else {
      this.noEncontrado = false;
    }
  }

  actualizarValor(nuevoValor: boolean) {
    this.pantallaSaleService.actualizarEstado(nuevoValor);
  }

}
