import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PantallaSaleService } from 'libreria-tienda';

@Component({
  selector: 'lib-busqueda',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './busqueda.component.html',
  styleUrl: './busqueda.component.css'
})
export class BusquedaComponent implements OnInit {

  sale: boolean;

  constructor(private router: Router) { }

  private pantallaSaleService = inject(PantallaSaleService)

  ngOnInit(): void {
    this.pantallaSaleService.estado$.subscribe(valor => {
      this.sale = valor;
    });
  }

  onEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const valorInput = (event.target as HTMLInputElement).value;
      console.log('Valor del input al presionar Enter:', valorInput);
      this.router.navigate(['/catalogue/' + 0 + '/' + valorInput],).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    }
  }
}
