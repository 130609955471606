import { Component, Input, OnInit, inject } from '@angular/core';
import { Banner, ExpressService, LoadingService } from 'libreria-tienda';

@Component({
  selector: 'lib-banners',
  standalone: true,
  imports: [],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.css'
})
export class BannersComponent implements OnInit {

  @Input() catalogo: string;
  @Input() set noEncontrado(value: boolean) {
    if (value) {
      this.getBanner('noEncontrado')
    }else{
      this.getBanner(this.catalogo)
    }
  }

  banners: Banner;

  public load = inject(LoadingService)
  public expressService = inject(ExpressService)

  ngOnInit() {
    this.getBanner(this.catalogo)
  }

  getBanner(seccion: string) {
    this.expressService.getBanners(seccion).subscribe(res => {
      this.banners = res.banners[0]
    })
  }

}
