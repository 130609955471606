import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { GetCabeceras, GetProductsByFilterNameCommand, filtroCadena, time } from '../interfaces/producto';
import { CommonLista } from '../interfaces/list';
import { Product, calculateTime, favorite } from '../class/product';
import { Deptos, Municipes, ciudades, puntosKevins } from '../interfaces/sitios';
import { ItemCar, ItemCompra } from '../class/carro-compras';



@Injectable({
  providedIn: 'root'
})
export class SioServicesService {

  constructor(private http: HttpClient, private configService: ConfigService) {
    // console.log(this.base);

  }

  urlService_Sio = this.configService._config.URLSERVICE_SIO

  GetProductsByFilterNameCommand(filters: GetProductsByFilterNameCommand) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<GetCabeceras>(this.urlService_Sio + '/GetDataProductoFiltradoQuery', filters, { headers: headersApi });
  }

  GetProductosBystring(cadena: filtroCadena): Observable<string> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlService_Sio + "/GetDataProductosFiltroUsuario", cadena, { headers: headersApi });
  }

  GetListaPorCodigo(_codTipoLista: string) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<CommonLista[]>(this.urlService_Sio + "/ObtenerListaPorCodigoQuery", { codTipoLista: _codTipoLista }, { headers: headersApi })
  }

  GetProductosGIAPorReferenciaQuery(refProv: string) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlService_Sio + '/GetProductosGIAPorReferenciaQuery', { referenciaProveedor: refProv }, { headers: headersApi });
  }

  GetTiempoEntregaProductosQuery(TiempoEntregaPeticion: calculateTime) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<time>(this.urlService_Sio + "/GetTiempoEntregaProductosQuery", TiempoEntregaPeticion, { headers: headersApi })
  }

  GetCiudadesKevins() {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<ciudades[]>(this.urlService_Sio + "/ObtenerCiudadesQuery", {}, { headers: headersApi })
  }

  GetDataListCommand(origen: string, predicado: string, valores: Array<string>) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Deptos[]>(this.urlService_Sio + "/GetDataListCommand", { origen: origen, predicado: predicado, valores: valores }, { headers: headersApi })
  }

  GetDataListCommandMunicipe(origen: string, predicado: string, valores: Array<string>) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Municipes[]>(this.urlService_Sio + "/GetDataListCommand", { origen: origen, predicado: predicado, valores: valores }, { headers: headersApi })
  }

  GetCentrodeCostoCommand(idCiudad: string) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    let Filtro = "predicado"
    let predicado = "PuntoActivo= @0 and idciudad= @1 and PuntodeVenta=@2"
    let valores = ["true", idCiudad.toString(), "true"]
    let objeto = { Filtro: Filtro, predicado: predicado, valores: valores }
    return this.http.post<puntosKevins[]>(this.urlService_Sio + "/GetCentrodeCostoCommand", { Filtro: Filtro, Predicado: predicado, valores: valores }, { headers: headersApi })
  }

  getCabeceras(): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<GetCabeceras>(this.urlService_Sio + "/GetDataProductoFiltradoQuery", {}, { headers: headersApi })
  }

  getPulseras(): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio
        // 'http://localhost:5070'
        + "/ExistenciaPulserasVeneziaQuery",
        {},
        { headers: headersApi }))
  }

  getDijes(): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio
        // 'http://localhost:5070'
        + "/ExistenciaDijesVeneziaQuery", {}, { headers: headersApi }))
  }

  getResumenArticulosPaginados(codigo: string, filtros: any[] = []): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(this.urlService_Sio + "/ConsultaResumidaDeProductosPorCodigoFiltro",
        // this.http.post<any>('http://localhost:5070' + "/ConsultaResumidaDeProductosPorCodigoFiltro",
        { CodigoFiltro: codigo, ListaFiltroPersonalizado: filtros },
        { headers: headersApi })
    )
  }

  getFavoritosPaginados(fav: favorite[], registrosPagina: number, pagina: number): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    // Utilizando lastValueFrom para convertir observable a promesa
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio + "/ExistenciaFavoritosQuery",
        // 'http://localhost:5070' + "/ExistenciaFavoritosQuery",
        { Productos: fav, RegistrosPorPagina: registrosPagina, NumeroPagina: pagina },
        { headers: headersApi }
      )
    );
  }

  getResumenFavoritosPaginados(productos: favorite[]): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(this.urlService_Sio + "/ResumidaDeFavoritosQuery",
        { Productos: productos },
        { headers: headersApi })
    )
  }

  // Info detalle Producto

  GetProductoExistenciasCiaByIdCommand(idProduct: string) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlService_Sio + '/GetProductoExistenciasCiaByIdCommand', { IdProducto: idProduct }, { headers: headersApi });
  }

  GetExistenciaProductoNormal(idProduct: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio + '/DetalleProductosQuery',
        // 'http://localhost:5070' + '/DetalleProductosQuery',
        { IdProducto: idProduct },
        { headers: headersApi }
      )
    );
  }

  GetExistenciaProductoGia(idProduct: string, pesoPiedra: number): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio + '/DetalleProductoGIAQuery',
        // 'http://localhost:5070' + '/DetalleProductoGIAQuery',
        { ReferenciaProveedor: idProduct, PesoPiedraCentral: pesoPiedra },
        { headers: headersApi }
      )
    );
  }

  GetExistenciaProductoAnillo(idProduct: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio + '/DetalleProductoAnilloQuery',
        // 'http://localhost:5070' + '/DetalleProductoAnilloQuery',
        { IdProducto: idProduct },
        { headers: headersApi }
      )
    );
  }

  GetExistenciaConjuntos(idProduct: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlService_Sio + '/ExistenciaConjuntosQuery',
        // 'http://localhost:5070' + '/ExistenciaConjuntosQuery',
        { IdProducto: idProduct },
        { headers: headersApi }
      )
    );
  }

  // Catalogo

  getArticulosPaginados(codigo: string, registrosPagina: number, pagina: number, filtros: any[] = [], precioMin: number = 0, precioMax: number = 0): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        // 'http://localhost:5070'
        this.urlService_Sio
        + "/ConsultaPaginadaDeProductosPorCodigoFiltro",
        {
          CodigoFiltro: codigo,
          NumeroPagina: pagina,
          RegistrosPorPagina: registrosPagina,
          ListaFiltroPersonalizado: filtros,
          PrecioMin: precioMin,
          PrecioMax: precioMax
        },
        { headers: headersApi })
    )
  }

  getTallasArticulo(codigo: string): Promise<any> {
    // console.log(codigo);

    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(this.urlService_Sio + "/ConsultaTallasAnillosQuery",
        // this.http.post<any>('http://localhost:5070' + "/ConsultaTallasAnillosQuery",
        { idProducto: codigo },
        { headers: headersApi })
    )
  }

  // Consulta de la información de los articulos en el carrito de compras

  getInfoArticulosCarrito(ItemsCompra: ItemCar[]): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      // this.http.post<any>('http://localhost:5070' + "/InfoCarritoCompraQuery",
      this.http.post<any>(this.urlService_Sio + "/InfoCarritoCompraQuery",
        { Articulos: ItemsCompra },
        { headers: headersApi })
    )
  }

  // Conuslta de tipo de producto

  getTipoArticulo(id: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      // this.http.post<any>('http://localhost:5070' + "/TipoProductoQuery",
      this.http.post<any>(this.urlService_Sio + "/TipoProductoQuery",
        { IdProducto: id },
        { headers: headersApi })
    )
  }

  // Conuslta filtros disponibles por codigo

  getFiltrosCodigo(codigo: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      // this.http.post<any>('http://localhost:5070' + "/FiltrosCodigoQuery",
      this.http.post<any>(this.urlService_Sio + "/FiltrosCodigoQuery",
        { Codigo: codigo },
        { headers: headersApi })
    )
  }
}



