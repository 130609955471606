import { Component, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';

declare var propiedades: any | undefined; // ver script javascript en el index.html

@Component({
  selector: 'lib-about-us',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.css'
})
export class AboutUsComponent implements OnInit {

  propiedades: any

  jsonAbout: TextResponse[]
  jsonService = inject(JsonTextService)
  configService = inject(ConfigService)

  constructor(private router: Router) {
    this.propiedades = propiedades;
  }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLJSONABOUT).subscribe(res => {
      this.jsonAbout = res
    })
  }
  getName(name: string) {
    return this.jsonAbout?.find(x => x.Nombre == name)?.Valor
  }

  navigate() {
    this.router.navigateByUrl(propiedades['about-us']['filtro'])
  }
}
