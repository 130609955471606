import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-pasos-compra',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pasos-compra.component.html',
  styleUrl: './pasos-compra.component.css'
})
export class PasosCompraComponent {

  @Input() paso: number

  aux : boolean = true
}
