<div class="d-flex-column resume-container center gap-20px pb-5">
  <h2 class="pt-5">{{getName('title')}}</h2>
  @if (section != 1) {
  <div class="w-100">
    @for (item of carrito; track $index) {
    <div class="d-flex-column pb-3">
      <div class="d-flex-row">
        <div class="pr-10">
          <img class="img-size" src="{{item.imagen}}" alt="">
        </div>
        <div class="d-flex-column space-around">
          <span class="text">REF. {{item.idProducto}}</span>
          <!-- <span class="text" *ngIf="item.talla != null">Talla: {{item.talla}}</span>
            <span class="text" *ngIf="item.talla == null">Talla: No tiene</span> -->
          <span class="text">Cantidad. {{item.cantidadSeleccionada}}</span>
        </div>
      </div>
    </div>
    }
  </div>
  }
  <div class="w-100 d-flex-column gap-20px">
    <div class="d-flex-row justify-space">
      <span class="subtotals">{{getName('sub')}}</span>
      <span class="subtotals">{{totalFull() | number}}</span>
    </div>
    <div class="d-flex-row justify-space">
      <span class="subtotals">Descuento</span>
      <span class="subtotals descuento">-{{subTotalDescuento() | number}}</span>
    </div>
    @if (section == 2) {
    <div class="d-flex-row justify-space">
      <span class="subtotals">Envio</span>
      <span class="subtotals">{{costoEnvio | number}}</span>
    </div>
    }
    <mat-divider></mat-divider>
    <div class="d-flex-row justify-space">
      <span class="totals">{{getName('tot')}}</span>
      <span class="totals total-total">{{total() | number}}</span>
    </div>
  </div>
  @if (section ==1) {
  <span class="text">{{getName('info')}}</span>
  }
  <span>{{getName('des')}}</span>
  <!-- Codigo descuento -->
  <div class="d-flex-row gap-40px w-100">
    <input type="text" class="pl-3-input w-inh" placeholder="Ingresa tu código">
    <button class="button-secondary">{{getName('desbut')}}</button>
  </div>
  @if (section == 1) {
  <button class="button-primary" (click)="comprar()">{{getName('but-pedido')}}</button>
  <div class="pb-5 w-100">
    <button (click)="goBuy()" class="button-secondary">{{getName('but-continue')}}</button>
  </div>
  }@else if (section == 2) {
  <div class="d-flex-column center w-100">
    <button (click)="pagar()" class="button-primary">{{getName('but-pagar')}}</button>
    @if (!validForm && section == 2 && submited && messageError!= '') {
    <div class="w-380">
      <mat-error class="error-check center flex pt-3"><mat-icon class="red w-50px mr-15">error_outline</mat-icon><span
          class="pl-1rem">{{messageError}}</span></mat-error>
    </div>
    }
  </div>
  <!-- <span class="methods">{{getName('Metodos de pago')}}</span> -->
  }
</div>
