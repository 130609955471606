import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-etiqueta-descuento-card',
  standalone: true,
  imports: [],
  templateUrl: './etiqueta-descuento-card.component.html',
  styleUrl: './etiqueta-descuento-card.component.css'
})
export class EtiquetaDescuentoCardComponent {

  _descuento: any;
  @Input() set descuento(value: number) {
    this._descuento = Math.trunc(value * 100);
  };



}
