import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MigaPanComponent } from 'libreria-tienda/shared/miga-pan/miga-pan.component';
import { FavoritoCorazonComponent } from 'libreria-tienda/sfa/components/favorito-corazon/favorito-corazon.component';
import { TallasComponent } from 'libreria-tienda/shared/tallas/tallas.component';
import { Anillo, ConfigService, ExpressService, Gia, InfoGia, Item, JsonTextService, LoadingService, ShoppingCartService, SioServicesService, TextResponse, caracteristicas, favorite, imagenesAux, infoVenecia, items } from 'libreria-tienda';
import { ActivatedRoute, Router } from '@angular/router';
import { ImgAuxiliarDetalleComponent } from 'libreria-tienda/shared/img-auxiliar-detalle/img-auxiliar-detalle.component';
import { DetallesProductoComponent } from 'libreria-tienda/shared/detalles-producto/detalles-producto.component';
import { SliderSieteVerticalComponent } from 'libreria-tienda/shared/slider-logo/slider-siete-vertical/slider-siete-vertical.component';
import { SliderCardComponent } from 'libreria-tienda/shared/slider-card/slider-card.component';



@Component({
  selector: 'app-detalle-producto',
  standalone: true,
  imports: [CommonModule, MigaPanComponent, FavoritoCorazonComponent, TallasComponent, ImgAuxiliarDetalleComponent,
    DetallesProductoComponent, SliderSieteVerticalComponent, SliderCardComponent],
  templateUrl: './detalle-producto.component.html',
  styleUrl: './detalle-producto.component.css'
})
export class DetalleProductoComponent implements OnInit, AfterViewInit {

  migas: string[] = [];
  abc: string[] = ['', '_B', '_C', '_D', '_E', '_F', '_G']

  categoria: any = '';
  infoSimilares: any = {};
  referencia: string = '';
  referenciaProveedor: any = '';
  esGia: string = 'false';

  complementos: infoVenecia[]

  tipoProducto: string;
  pesoPiedra: string;

  item: Item = new Item();
  itemGia: Gia[] = [];
  itemAnillo: Anillo = new Anillo();

  imagenes: imagenesAux[] = [];

  imagenMostrar: string

  isFav: boolean = false;

  canti: number = 0;

  jsonDetalle: TextResponse[];

  idProducto: string = '';


  private favorite: favorite[] = [];

  public jsonService = inject(JsonTextService);
  public configService = inject(ConfigService);
  public sioservice = inject(SioServicesService);
  public expressService = inject(ExpressService);
  public cartService = inject(ShoppingCartService);
  public load = inject(LoadingService);

  private router = inject(Router);
  private route = inject(ActivatedRoute);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.load.cargar(true)

    this.route.paramMap.subscribe(params => {
      this.migas = []
      this.idProducto = (params.get('idProducto') ?? '');
    });

    if (this.idProducto == '') {
      this.route.queryParamMap.subscribe(params => {
        this.migas = [];
        this.categoria = (params.get('catalogo') ?? '0');
        this.referencia = (params.get('producto') ?? '0');
        this.esGia = (params.get('GIA') ?? 'false');
        this.referenciaProveedor = (params.get('referenciaProveedor') ?? '0');
        this.tipoProducto = (params.get('tipoProducto') ?? '0');
        this.pesoPiedra = (params.get('pesoPiedra') ?? '0');
        this.migas.push(this.categoria == '0' ? 'favoritos' : this.categoria);
        this.migas.push(this.esGia == 'true' ? this.referenciaProveedor : this.referencia);
        if (this.esGia == 'true' && this.referencia !== '') {
          this.migas.push(this.referencia);
          this.ProductosGia(this.referenciaProveedor, this.pesoPiedra + "");
        } else {
          // console.log(this.tipoProducto);
          if (this.referencia !== undefined) {
            this.Conjuntos(this.referencia)
          }
          switch (this.tipoProducto) {
            case 'Anillo':
              this.ProductosAnillo(this.referencia);
              break;
            case 'Gia':
              this.ProductosGia(this.referenciaProveedor, this.pesoPiedra + "");
              break;
            case 'Venezia':
              // this.ProductosVenezia(this.referencia);
              this.ProductosNormal(this.referencia);
              break;
            default:
              this.ProductosNormal(this.referencia);
              break;
          }
        }
      })
    } else {
      this.sioservice.getTipoArticulo(this.idProducto).then((info) => {
        // console.log(info.valor);
        this.referenciaProveedor = info.valor.referenciaProveedor;
        switch (info.valor.tipoProducto) {
          case 'Anillo':
            this.ProductosAnillo(this.idProducto);
            break;
          case 'Gia':
            this.esGia = 'true'
            this.ProductosGia(info.valor.referenciaProveedor, info.valor.pesoPiedraCentral + "");
            break;
          case 'Venezia':
            // this.ProductosVenezia(this.referencia);
            this.ProductosNormal(this.idProducto);
            break;
          default:
            this.ProductosNormal(this.idProducto);
            break;
        }
      }).catch((error) => {
        console.error(error);
        this.load.cargar(false);
      });
    }





  }

  ngOnInit() {
    this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
      this.jsonDetalle = res;
    })
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem("filtros") != undefined) {
        let aux = JSON.parse(localStorage.getItem("filtros") || "")
        this.infoSimilares.filtros = aux.filtros
        this.infoSimilares.categoria = this.categoria
        this.infoSimilares.item = this.referencia
      } else {
        this.infoSimilares.filtros = []
        this.infoSimilares.categoria = this.categoria
        this.infoSimilares.item = this.referencia
      }
    }

  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  Conjuntos(referencia: string) {
    this.sioservice.GetExistenciaConjuntos(referencia).then((info) => {
      // console.log(info.valor);
      this.complementos = info.valor
      // console.log(this.complementos[0].manejaTalla);
      this.complementos.forEach(element => {
        element.imagen = this.configService._config.URLBASEIMG + element.idProducto + ".jpg";
      });
    });
  }

  ProductosNormal(referencia: string) {
    this.sioservice.GetExistenciaProductoNormal(referencia).then((info) => {
      // console.log(info);
      if (info.exitoso) {
        // console.log(info.valor);
        this.fotosSegundarias(referencia);
        this.item.idProducto = info.valor.idProducto;
        this.item.cantidadDisponible = info.valor.cantidad;
        this.item.categoria = this.categoria;
        this.item.precioFull = info.valor.precioFull;
        this.item.precioAutorizado = info.valor.precioAutorizado;
        this.item.porcentaje = info.valor.porcentaje * 100;
        this.item.imagen = this.configService._config.URLBASEIMG + referencia + ".jpg";
        this.item.tipoProducto = 'Normal'
        this.item.descripcion = info.valor.descripcionWeb
        this.item.caracteristicas = JSON.parse(info.valor.detalle);
        this.item.catalogo = info.valor.catalogo

        this.buscarFav();

        this.load.cargar(false);
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  ProductosGia(referencia: string, pesoPiedra: string) {
    this.sioservice.GetExistenciaProductoGia(referencia, parseFloat(pesoPiedra)).then((info) => {
      // console.log(info);
      if (info.exitoso) {
        this.item.tipoProducto = 'Gia'
        if (this.esGia == 'true' && this.referencia !== '') {
          this.fotosSegundarias(this.referencia);
          this.item.imagen = this.configService._config.URLBASEIMG + this.referencia + ".jpg";
        } else {
          this.fotosSegundarias(info.valor[0].idProducto);
          this.item.imagen = this.configService._config.URLBASEIMG + info.valor[0].idProducto + ".jpg";;
        }
        for (let i = 0; i < info.valor.length; i++) {
          let aux: Gia = new Gia();
          aux.ReferenciaProveedor = referencia;
          aux.talla = info.valor[i].talla;
          aux.categoria = this.categoria;
          aux.claridad = info.valor[i].claridad;
          aux.color = info.valor[i].colorPiedra;
          aux.idProducto = info.valor[i].idProducto;
          aux.imagen = this.configService._config.URLBASEIMG + info.valor[i].idProducto + ".jpg";
          aux.pesoCentral = parseInt(pesoPiedra);
          aux.porcentaje = info.valor[i].porcentaje * 100;
          aux.precioAutorizado = info.valor[i].precioAutorizado;
          aux.precioFull = info.valor[i].precioFull;
          aux.tipoProducto = info.valor[i].tipoProducto;
          aux.descripcion = info.valor[i].descripcionWeb;
          aux.caracteristicas = info.valor[i].detalle;
          this.itemGia.push(aux)
        }
        this.item.idProducto = this.referenciaProveedor;
        this.buscarFav();

        // Acá es donde se debe tratar las descripciones
        // this.item.descripcion

        // this.item.talla = "-";

        this.load.cargar(false);
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  ProductosAnillo(referencia: string) {
    this.sioservice.GetExistenciaProductoAnillo(referencia).then((info) => {
      // console.log(info);
      if (info.exitoso) {
        this.fotosSegundarias(referencia);
        this.item.idProducto = info.valor.idProducto;
        this.item.cantidadDisponible = 0;
        this.item.categoria = this.categoria;
        this.item.precioFull = info.valor.precioFull;
        this.item.precioAutorizado = info.valor.precioAutorizado;
        this.item.porcentaje = info.valor.porcentaje * 100;
        this.item.imagen = this.configService._config.URLBASEIMG + referencia + ".jpg";
        this.item.tipoProducto = 'Anillo';
        this.item.descripcion = info.valor.descripcionWeb
        this.item.caracteristicas = JSON.parse(info.valor.detalle);
        this.item.catalogo = info.valor.catalogo
        this.itemAnillo.TallaSeleccionada = "-";
        this.itemAnillo.CantidadSeleccionada = 0;
        this.itemAnillo.TallasCantidad = info.valor.existencia;
        // this.item.esGia = this.esGia == 'true' ? true : false;

        this.buscarFav();

        // Acá es donde se debe tratar las descripciones
        // this.item.descripcion

        // this.item.talla = "-";

        this.load.cargar(false);
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  ProductosVenezia(referencia: string) {

  }

  changePhoto(i: string) {
    this.imagenMostrar = i
  }

  fotosSegundarias(aux: string) {
    this.imagenes = [];
    for (let index = 0; index < this.abc.length; index++) {
      let auxImg: imagenesAux = new imagenesAux()
      auxImg.img = this.configService._config.URLBASEIMG + aux + this.abc[index] + ".jpg"
      this.imagenes.push(auxImg)
    }
    this.imagenes = this.eliminarErrorImagen(this.imagenes)
    this.imagenMostrar = this.imagenes[0].img
  }

  eliminarErrorImagen(arreglo: imagenesAux[]) {
    this.imagenes = [];
    if (isPlatformBrowser(this.platformId)) {
      for (let i = 0; i < arreglo.length; i++) {
        const img = new Image();
        img.src = arreglo[i].img;
        img.onerror = () => {
          arreglo[i].error = true
        };
      }
    }
    return arreglo
  }

  buscarFav() {
    if (this.favorite.length > 0) {
      if (this.favorite.find(it => it.idProducto == this.item.idProducto)) {
        this.isFav = true
      } else {
        this.isFav = false
      }
    }
  }
}
